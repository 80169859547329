<template>
  <div>
    <BaseLineChartCard
      :labelsArray="getLabelsArray(jobsStats.jobsApplicationsCountPerDuration)"
      chartLabel="متقدم لفرصة عمل"
      :dataArray="getDataArray(jobsStats.jobsApplicationsCountPerDuration)"
    >
      <template v-slot:title> عدد المتقدمين لفرص العمل خلال </template>
      <template v-slot:durationSelection>
        <BaseSelect
          :items="durations"
          v-model="jobsStats.jobApplicationsCountDuration"
          chips
          dense
          placeholder="المدة الزمنية"
          value="value"
        >
          <template v-slot:item="{ item }"> خلال {{ item.label }} </template>
          <template v-slot:selection="{ item }">
            خلال {{ item.label }}
          </template>
        </BaseSelect>
      </template>
    </BaseLineChartCard>
  </div>
</template>

<script>
import { useStatisticsStore } from "@/modules/home/store/StatisticsStore";
import { mapActions, mapWritableState } from "pinia";
import BaseLineChartCard from "../../base_statistics_cards/BaseLineChartCard.vue";
import StatisticsDurations from "../../statics/StatisticsDurations";

export default {
  components: { BaseLineChartCard },
  methods: {
    ...mapActions(useStatisticsStore, [
      "getLabelsArray",
      "getDataArray",
      "fetchJobsStatistics",
    ]),
  },
  watch: {
    "jobsStats.jobApplicationsCountDuration": function () {
      this.fetchJobsStatistics()
        .then()
        .catch((err) => console.error(err));
    },
  },
  computed: {
    ...mapWritableState(useStatisticsStore, ["jobsStats"]),
  },
  data() {
    return {
      durations: StatisticsDurations,
    };
  },
};
</script>
