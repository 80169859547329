<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> المشاريع المميزة </template>
    <template v-slot:tableActions>
      <CreateProjectButton />
    </template>
    <template v-slot:dataTable>
      <ProjectsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import CreateProjectButton from "../components/action_buttons/CreateProjectButton.vue";
import ProjectsTable from "../components/ProjectsTable.vue";

export default {
  components: { CreateProjectButton, ProjectsTable },
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "المشاريع المميزة",
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style></style>
