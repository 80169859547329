<template>
  <BaseTrainingProgramForm
    :trainingProgramObject="trainingProgram"
    :isEdit="true"
    formActionName="تعديل المسار"
    @trainingProgramSubmitted="
      (submittedTB, video) => sendTrainingProgramData(submittedTB, video)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useTrainingProgramStore } from "../../store/TrainingProgramStore";

import BaseTrainingProgramForm from "./BaseTrainingProgramForm.vue";

export default {
  props: {
    trainingProgram: Object,
  },
  methods: {
    ...mapActions(useTrainingProgramStore, ["editTrainingProgram"]),
    sendTrainingProgramData(tp, video) {
      this.editTrainingProgram(this.trainingProgram.id, tp, video)
        .then(() => {
          if (video == null) {
            this.$router.push("/training-programs");
          }
        })
        .catch();
    },
  },
  components: { BaseTrainingProgramForm },
};
</script>

<style scoped></style>
