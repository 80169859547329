<template>
  <BaseCRUDPageLayout :links="links">
    <v-container>
      <v-row v-if="!fetchingData" align="start">
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <ApplicantDetails :application="applicant" />
        </v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <PassApplicantButton
            :item="item"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <RejectApplicantButton
            :item="item"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useJobApplicantsStore } from "../store/JobApplicantsStore";
import ApplicantDetails from "../components/ApplicantDetails.vue";
import PassApplicantButton from "../components/action_buttons/applicant_actions/PassApplicantButton.vue";
import RejectApplicantButton from "../components/action_buttons/applicant_actions/RejectApplicantButton.vue";

export default {
  mounted() {
    var jobId = this.$route.params.jobId;
    this.setJobId(jobId);
    var applicantId = this.$route.params.applicantId;
    this.fetchJobApplicant(applicantId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useJobApplicantsStore, ["applicant"]),
  },
  methods: {
    ...mapActions(useJobApplicantsStore, ["fetchJobApplicant", "setJobId"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "فرصة العمل رقم " + this.$route.params.jobId,
          href: "/job-opportunities/" + this.$route.params.jobId,
          disabled: false,
        },
        {
          text: "المتقدمين",
          href:
            "/job-opportunities/" + this.$route.params.jobId + "/applicants",
          disabled: false,
        },
        {
          text: "المتقدم رقم " + this.$route.params.applicantId,
          disabled: true,
        },
      ],
    };
  },
  components: {
    ApplicantDetails,
    PassApplicantButton,
    RejectApplicantButton,
  },
};
</script>
