<template>
  <BaseTableItemAction
    v-can:[editPermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-pencil"
    actionName="تعديل"
    @click="openEditDialog()"
  >
    <slot></slot>
    <EditEthicalAdviceDialog
      :item="item"
      :showCondition="showEditDialog"
      @closed="closeEditDialog"
    />
  </BaseTableItemAction>
</template>

<script>
import EditEthicalAdviceDialog from "../dialogs/EditEthicalAdviceDialog.vue";
import { WRITE_ETHICAL_ADVICES_PERMISSION } from "@/core/statics/Permissions";

export default {
  components: { EditEthicalAdviceDialog },
  props: {
    item: Object,
  },
  data() {
    return {
      editPermission: WRITE_ETHICAL_ADVICES_PERMISSION,
      showEditDialog: false,
    };
  },
  methods: {
    openEditDialog() {
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.showEditDialog = false;
    },
  },
};
</script>

<style></style>
