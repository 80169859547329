<template>
  <BaseDataTable :headers="headers" :paginatedItems="paginatedJobFields"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)">
    <template v-slot:actions="{ item }">
      <DeleteJobFieldButton :item="item" />
      <EditJobFieldButton :item="item" />
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobFieldsStore } from "../store/JobFieldsStore";

import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_ADMINS_PERMISSION } from "@/core/statics/Permissions";
import DeleteJobFieldButton from "./action_buttons/DeleteJobFieldButton.vue";
import EditJobFieldButton from "./action_buttons/EditJobFieldButton.vue";

export default {
  mixins: [PermissionManagerMixin],
  components: { DeleteJobFieldButton, EditJobFieldButton },
  computed: {
    ...mapState(useJobFieldsStore, ["paginatedJobFields"]),
    ...mapWritableState(useJobFieldsStore, ["searchKey"]),
  },
  data() {
    return {
      showAdminPermission: READ_ADMINS_PERMISSION,
      headers: [
        { text: "الرقم", value: "id" },
        { text: "اسم المجال", value: "name" },
        { text: "تاريخ الإضافة ", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchJobFields()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useJobFieldsStore, [
      "fetchJobFields",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  destroyed() {
    this.searchKey = null;
  }
};
</script>
