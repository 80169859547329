<template>
  <BaseTableItemAction
    v-if="item.applicantsCount > 0"
    v-can:[showPermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-account-group"
    actionName="عرض المتقدمين"
    :to="'/job-opportunities/' + item.id + '/applicants'"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      showPermission: READ_JOBS_PERMISSION,
    };
  },
};
</script>
