<template>
  <div>
    <div>
      <h2 class="secondary--text d-inline ml-2">
        المتقدم رقم {{ application.id }}
      </h2>
      <v-chip dark color="primary">
        حالة الطلب:
        {{ translateProp(application.status) }}
      </v-chip>
    </div>

    <div class="my-5">
      <span class="primary--text">
        اسم المتقدم:
      </span>
      {{ application.applicant.name }}
    </div>
    <div class="my-5">
      <span class="primary--text">
        رقم الهاتف الخاص بالمتقدم:
      </span>
      {{ application.applicant.phoneNumber }}
    </div>
    <div class="my-5">
      <span class="primary--text">
        البريد الالكتروني الخاص بالمتقدم:
      </span>
      {{ application.applicant.email }}
    </div>
    <v-checkbox
      readonly
      v-if="application.isOrganizationGraduate"
      label="المتقدم من خريجي الجمعية"
      :input-value="true"
    ></v-checkbox>

    <div class="my-5" v-if="application.rate">
      <span class="primary--text">
        تقييم الناشر للمتقدم:
      </span>
      <v-rating
        color="accent"
        length="5"
        readonly
        size="34"
        :value="application.rate"
      ></v-rating>
      <div class="my-2">
        <span class="primary--text">
          محتوى التقييم:
        </span>
        {{ application.rateContent }}
      </div>
    </div>

    <v-btn
      color="accent"
      class="my-5"
      dark
      :href="application.jobResume"
      target="_blank"
    >
      عرض السيرة الذاتية
      <v-icon dark>mdi-file</v-icon>
    </v-btn>
  </div>
</template>

<script>
import JobApplication from "../models/JobApplication";

export default {
  props: {
    application: Object,
  },
  methods: {
    translateProp(prop) {
      return JobApplication.trans(prop);
    },
  },
};
</script>

<style scoped></style>
