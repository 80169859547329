<template>
    <!--TODO make sure of all action buttons show conditions-->
    <BaseTableItemAction
      v-if="item.status == 'pending'"
      v-can:[passPermission]
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-check"
      actionName="تمرير المتقدم"
      @click="openPassApplicantDialog()"
    >
      <PassApplicantConfirmationDialog
        :showCondition="showPassDialog"
        :item="item"
        @closed="closePassApplicantDialog()"
      />
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";
  import PassApplicantConfirmationDialog from "../../dialogs/applicant_dialogs/PassApplicantConfirmationDialog.vue";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        passPermission: WRITE_JOBS_PERMISSION,
        showPassDialog: false,
      };
    },
    methods: {
      openPassApplicantDialog() {
        this.showPassDialog = true;
      },
      closePassApplicantDialog() {
        this.showPassDialog = false;
      },
    },
    components: { PassApplicantConfirmationDialog },
  };
  </script>
  