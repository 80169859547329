<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تعديل مشرف </template>
    <v-container>
      <v-row>
        <v-col v-if="!fetchingData" cols="12">
          <EditAdminForm :admin="admin" />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "../store/AdminStore";

import EditAdminForm from "../components/forms/EditAdminForm.vue";

export default {
  mounted() {
    var adminId = this.$route.params.id;
    this.fetchAdmin(adminId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useAdminStore, ["admin"]),
  },
  methods: {
    ...mapActions(useAdminStore, ["fetchAdmin"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "مشرفي المنصة",
          href: "/admins",
          disabled: false,
        },
        {
          text: "تعديل المشرف رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { EditAdminForm },
};
</script>
