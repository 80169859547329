<template>
  <div>
    <div class="my-5">
      <span class="primary--text">نص حقل السؤال : </span>{{ question.label }}
    </div>
    <div class="my-5">
      <span class="primary--text">نمط حقل السؤال : </span
      >{{ translateProp(question.type) }}
    </div>
    <div class="my-5" v-if="question.answers">
      <span class="primary--text">
        الإجابات المحتملة:
      </span>
      <v-chip v-for="answer in question.answers" :key="answer">
        {{ answer }}
      </v-chip>
    </div>
    <div class="my-5" v-if="question.placeholder">
      <span class="primary--text">
        تلميح حقل السؤال : </span
      >{{ question.placeholder }}
    </div>
    <div class="my-5" v-if="question.note">
      <span class="primary--text">
        ملاحظة حقل السؤال : </span
      >{{ question.placeholder }} {{ question.note }}
    </div>
    <v-checkbox
      readonly
      label="الإجابة إجبارية"
      :input-value="question.isRequired"
    ></v-checkbox>
  </div>
</template>

<script>
import TrainingProgramQuestion from "../models/TrainingProgramQuestion";

export default {
  props: {
    question: Object,
  },
  methods: {
    translateProp(prop) {
      return TrainingProgramQuestion.trans(prop);
    },
  },
};
</script>

<style scoped></style>
