import UserLayout from "@/core/layouts/UserLayout.vue";
import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";
import JobOpportunitiesPage from "../pages/JobOpportunitiesPage.vue";
import JobOpportunitiesFeedbackPage from "../pages/JobOpportunitiesFeedbackPage.vue";
import JobOpportunityPage from "../pages/JobOpportunityPage.vue";
import JobStatusChangeRequestsPage from "../pages/JobStatusChangeRequestsPage.vue";
import JobApplicantsPage from "../pages/JobApplicantsPage.vue";
import JobApplicantPage from "../pages/JobApplicantPage.vue";

export default {
  path: "/job-opportunities",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "job_opportunities",
      component: JobOpportunitiesPage,
      meta: {
        title: "فرص العمل",
        requiredPermission: READ_JOBS_PERMISSION,
      },
    },
    {
      path: "/job-opportunities/feedback",
      name: "job_opportunities_feedback",
      component: JobOpportunitiesFeedbackPage,
      meta: {
        title: "الملاحظات المرسلة على فرص العمل",
        requiredPermission: READ_JOBS_PERMISSION,
      },
    },
    {
      path: "/job-opportunities/:id",
      name: "job_opportunity",
      component: JobOpportunityPage,
      meta: {
        title: "فرصة عمل",
        requiredPermission: READ_JOBS_PERMISSION,
      },
    },
    {
      path: "/job-opportunities/:id/status-change-requests",
      name: "job_opportunity_status_change_requests",
      component: JobStatusChangeRequestsPage,
      meta: {
        title: "أسباب تغيير حالة فرصة العمل",
        requiredPermission: READ_JOBS_PERMISSION,
      },
    },
    {
      path: "/job-opportunities/:id/applicants",
      name: "job_opportunity_applicants",
      component: JobApplicantsPage,
      meta: {
        title: "المتقدمين على فرصة العمل",
        requiredPermission: READ_JOBS_PERMISSION,
      },
    },
    {
      path: "/job-opportunities/:jobId/applicants/:applicantId",
      name: "job_opportunity_applicant",
      component: JobApplicantPage,
      meta: {
        title: "متقدم على فرصة العمل",
        requiredPermission: READ_JOBS_PERMISSION,
      },
    },
  ],
};
