<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
import { EventBus } from "@/main";
export default {
  name: "BaseFormPageWrapper",
  created() {
    window.addEventListener("beforeunload", this.preventNav);
    EventBus.$on(
      "formChanged",
      function () {
        this.dataChanged = true;
        EventBus.$off("formChanged");
      }.bind(this)
    );
    EventBus.$on(
      "formSubmitted",
      function () {
        this.dataChanged = false;
        EventBus.$off("formSubmitted");
      }.bind(this)
    );
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  data() {
    return {
      dataChanged: false,
    };
  },
  methods: {
    preventNav(event) {
      if (!this.dataChanged) return;
      else {
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      const answer = window.confirm("هل تريد مغادرة الصفحة دون حفظ التغيرات؟");
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
