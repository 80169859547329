import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import JobApplication from "../JobApplication";

export default class PaginatedJobApplicationsCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((jobApplicant) => {
      this.collection.push(new JobApplication(jobApplicant));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((jobApplicant) => {
      this.collection.push(new JobApplication(jobApplicant));
    });
  }
}
