<template>
  <v-btn class="accent px-8" large rounded @click="sendScheduling()">
    إرسال الجدولة
  </v-btn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useEthicalAdvicesStore } from "../../store/EthicalAdvicesStore";

export default {
  computed: {
    ...mapState(useEthicalAdvicesStore, ["schedulingCalendar"]),
  },
  methods: {
    ...mapActions(useEthicalAdvicesStore, [
      "sendEthicalAdvicesSchedulingCalendar",
    ]),
    sendScheduling() {
      const data =({ dates: this.schedulingCalendar });
      this.sendEthicalAdvicesSchedulingCalendar(data)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style></style>
