<template>
  <BaseTableItemAction
    v-can:[viewPermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-eye"
    actionName="عرض"
    :to="'/projects/' + this.item.id"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_PROJECTS_PERMISSION } from "@/core/statics/Permissions";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      viewPermission: WRITE_PROJECTS_PERMISSION,
    };
  },
};
</script>
