<template>
  <BaseFAQForm
    :faqObject="faq"
    formActionName="تعديل السؤال"
    @faqSubmitted="(submittedFaq) => sendFaqData(submittedFaq)"
  />
</template>

<script>
import { mapActions } from "pinia";
import { useFAQStore } from "../../store/FAQStore";
import BaseFAQForm from "./BaseFAQForm.vue";

export default {
  props: {
    faq: Object,
  },
  methods: {
    ...mapActions(useFAQStore, ["editFAQ"]),
    sendFaqData(faqData) {
      this.editFAQ(this.faq.id, faqData)
        .then(() => {
          this.$router.push("/faqs");
        })
        .catch();
    },
  },
  components: { BaseFAQForm },
};
</script>

<style scoped></style>
