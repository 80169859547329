import Notification from "../Notification";

export default class NewApplicantNotification extends Notification{
    constructor(data){
        super(data);
        this.jobId=data.data.job_id;
        this.jobPublisherNameTitle=data.data.job_publisher_name;
        this.jobTitle=data.data.job_title;
        this.applicationId=data.data.application_id;
        this.applicantName=data.data.applicant_name;
    }
    getTitle(){
        return 'ملاحظة جديدة على متقدم على فرصة عمل '
    }
    getBody(){
        return ` قام ناشر فرص العمل "${this.jobPublisherNameTitle}" بإرسال ملاحظة على   "${this.applicantName}" المتقدم على فرصة العمل "${this.jobTitle}"`
    }
    getActionLink(){
        return `/job-opportunities/feedback#publishers-feedback`
    }
}