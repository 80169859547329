import UserLayout from "@/core/layouts/UserLayout.vue";
import CareerAptitudeTestQuestionsPage from "../pages/CareerAptitudeTestQuestionsPage.vue";
import { READ_CAREER_TEST_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";

export default {
  path: "/career-test-questions",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "CareerAptitudeTestQuestionsPage",
      component: CareerAptitudeTestQuestionsPage,
      meta: {
        title: "أسئلة اختبار الميول المهني",
        requiredPermission: READ_CAREER_TEST_QUESTIONS_PERMISSION,
      },
    },
  ],
};
