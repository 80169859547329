<template>
  <div>
    <BaseDoughnutChartCard
      :labelsArray="
        getLabelsArray(usersStats.careerAptitudeTestResultsCountPerDuration)
      "
      chartLabel="عدد النتائج"
      :dataArray="
        getDataArray(usersStats.careerAptitudeTestResultsCountPerDuration)
      "
    >
      <template v-slot:title>
        نتائج اختبار الميول المهني وتكرار كل نتيجة
      </template>
      <template v-slot:durationSelection>
        <BaseSelect
          :items="durations"
          v-model="usersStats.careerAptitudeTestResultsCountDuration"
          chips
          dense
          placeholder="المدة الزمنية"
          value="value"
        >
          <template v-slot:item="{ item }"> خلال {{ item.label }} </template>
          <template v-slot:selection="{ item }">
            خلال {{ item.label }}
          </template>
        </BaseSelect>
      </template>
    </BaseDoughnutChartCard>
  </div>
</template>

<script>
import { useStatisticsStore } from "@/modules/home/store/StatisticsStore";
import { mapActions, mapWritableState } from "pinia";
import BaseDoughnutChartCard from "../../base_statistics_cards/BaseDoughnutChartCard.vue";
import StatisticsDurations from "../../statics/StatisticsDurations";

export default {
  components: { BaseDoughnutChartCard },
  methods: {
    ...mapActions(useStatisticsStore, [
      "getLabelsArray",
      "getDataArray",
      "fetchUsersStatistics",
    ]),
  },
  watch: {
    "usersStats.careerAptitudeTestResultsCountDuration": function () {
      this.fetchUsersStatistics()
        .then()
        .catch((err) => console.error(err));
    },
  },
  computed: {
    ...mapWritableState(useStatisticsStore, ["usersStats"]),
  },
  data() {
    return {
      durations: StatisticsDurations,
    };
  },
};
</script>
