import BaseService from "@/core/classes/base/BaseService";
import {
   getSuggestingStatusAPI,
   getTrainingProgramSuggestionsAPI,
   toggleSuggestingStatusAPI
} from "./TrainingProgramSuggestions.api";
import Client from "@/core/classes/client/Client";

export default class TrainingProgramSuggestionsService extends BaseService {
  static getSuggestingStatus() {
    return Client.get(getSuggestingStatusAPI).then((res) => Promise.resolve(res));
  }
  static getTrainingProgramSuggestionsAPI(params) {
    return Client.get(getTrainingProgramSuggestionsAPI,params).then((res) => Promise.resolve(res));
  }
  static toggleSuggestingStatus() {
    return Client.put(toggleSuggestingStatusAPI,{}).then((res) => Promise.resolve(res));
  }
}