import UserLayout from "@/core/layouts/UserLayout.vue";
import EthicalAdvicesPage from "../pages/EthicalAdvicesPage.vue";
import ScheduleEthicalAdvicesPage from "../pages/ScheduleEthicalAdvicesPage.vue";
import { READ_ETHICAL_ADVICES_PERMISSION } from "@/core/statics/Permissions";
import { WRITE_ETHICAL_ADVICES_PERMISSION } from "@/core/statics/Permissions";

export default {
  path: "/ethical-advices",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "ethical_advices",
      component: EthicalAdvicesPage,
      meta: {
        title: "النصائح الأخلاقية",
        requiredPermission: READ_ETHICAL_ADVICES_PERMISSION,
      },
    },
    {
      path: "schedule",
      name: "schedule_ethical_advices",
      component: ScheduleEthicalAdvicesPage,
      meta: {
        title: "جدولة النصائح الأخلاقية",
        requiredPermission: WRITE_ETHICAL_ADVICES_PERMISSION,
      },
    },
  ],
};
