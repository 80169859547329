export const getJobsAPI="/jobs";
export const getExportJobsAPI="/jobs/export";
export function getJobAPI(id){ return "/jobs/"+id;}
export function getJobStatusChangeRequestsAPI(id){ return "/jobs/"+id+"/action-requests";}
export function acceptJobAPI(id){ return "/jobs/"+id+"/approve";}
export function rejectJobAPI(id){ return "/jobs/"+id+"/disapprove";}
export function closeJobAPI(id){ return "/jobs/"+id+"/close";}
//applicants
export function getJobApplicantsAPI(id) {return "/jobs/" + id + "/applications";}
export function getJobApplicantAPI(applicantId) {return "/job-applications/"+applicantId;}
export function passJobApplicantAPI(applicantId) {return "/job-applications/"+applicantId+"/pass";}
export function rejectJobApplicantAPI(applicantId) {return "/job-applications/"+applicantId+"/refuse";}
//feedback
export const getApplicantsFeedbackAPI="/jobs/applicants-feedback";
export const getPublishersFeedbackAPI="/jobs/publishers-feedback";
