<template>
  <div>
    <v-btn
      v-can:[formatPermission]
      to="/training-program-applications/export-keys"
      color="primary"
    >
      <v-icon class="ml-3">mdi-format-list-numbered</v-icon>
      تنسيق تصدير الطلبات
    </v-btn>
  </div>
</template>
<script>
import { WRITE_TP_APPLICATIONS_PERMISSION } from "@/core/statics/Permissions";

export default {
  data() {
    return {
      formatPermission: WRITE_TP_APPLICATIONS_PERMISSION,
    };
  },
  methods: {},
};
</script>
