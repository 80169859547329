import keysTransformations from "./key_transformations/JobStatusChangeRequestKeyTransformations";
import { jobTranslations } from "./static_enum_translations/JobEnums";

export default class JobStatusChangeRequest {
  constructor(data) {
    this.id = data?.id;
    this.createdAt = data?.created_at;
    this.requestType = data?.action_type;
    this.reason = data?.reason;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }

  static trans(prop) {
    return jobTranslations[prop];
  }
}
