import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import NotificationFactory from "./NotificationFactory";

export default class PaginatedNotificationCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(notification => {
      this.collection.push(NotificationFactory.wrapNotification(notification));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(notification => {
      this.collection.push(NotificationFactory.wrapNotification(notification));
    });
  }
}
