export default class CareerAptitudeTestQuestion {
  id;
  question;
  type;
  adderName;
  adderId;
  createdAt;
  constructor(data) {
    this.id = data?.id;
    this.question = data?.question;
    this.type = data?.type;
    this.adderName = data?.added_by?.name;
    this.adderId = data?.adder_id;
    this.createdAt = data?.created_at?.substring(0,10);
  }
}
