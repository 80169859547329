<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName>مشرفي المنصة </template>
    <template v-slot:tableActions>
      <CreateAdminButton />
    </template>
    <template v-slot:dataTable>
      <AdminsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import CreateAdminButton from "../components/action_buttons/CreateAdminButton.vue";
import AdminsTable from "../components/AdminsTable.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "مشرفي المنصة",
          disabled: true,
        },
      ],
    };
  },
  components: {
    AdminsTable,
    CreateAdminButton,
  },
};
</script>
