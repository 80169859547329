<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> ناشري فرص العمل </template>
    <template v-slot:dataTable>
      <JobPublishersTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import JobPublishersTable from '../components/JobPublishersTable.vue';

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "ناشري فرص العمل",
          disabled: true,
        },
      ],
    };
  },
  components: {JobPublishersTable},
};
</script>
