import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import TrainingProgramQuestion from "./TrainingProgramQuestion";

export default class PaginatedTrainingProgramQuestionCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(q => {
      this.collection.push(new TrainingProgramQuestion(q));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(q => {
      this.collection.push(new TrainingProgramQuestion(q));
    });
  }
}
