<template>
  <div class="my-10">
    <h1 class="secondary--text">مرحباً بك {{ username }}!</h1>
    <p class="primary--text">يمكنك الانتقال بين عناصر التحكم من خلال اختيار إحدى خيارات الشريط الجانبي......</p>
  </div>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store";
import { mapState } from "pinia";

export default {
  computed: {
    ...mapState(useAuthStore, ["username"]),
  },
};
</script>
