<template>
  <div>
    <BaseBarChartCard
      :labelsArray="getLabelsArray(TPAppsStats.TPAppsCountPerDuration)"
      chartLabel="عدد الطلبات"
      :dataArray="getDataArray(TPAppsStats.TPAppsCountPerDuration)"
    >
      <template v-slot:title> عدد طلبات المنح التدريبية </template>
      <template v-slot:durationSelection>
        <BaseSelect
          :items="durations"
          v-model="TPAppsStats.TPAppsCountDuration"
          chips
          dense
          placeholder="المدة الزمنية"
          value="value"
        >
          <template v-slot:item="{ item }"> خلال {{ item.label }} </template>
          <template v-slot:selection="{ item }">
            خلال {{ item.label }}
          </template>
        </BaseSelect>
      </template>
    </BaseBarChartCard>
  </div>
</template>

<script>
import { useStatisticsStore } from "@/modules/home/store/StatisticsStore";
import { mapActions, mapWritableState } from "pinia";
import BaseBarChartCard from "../../base_statistics_cards/BaseBarChartCard.vue";
import StatisticsDurations from "../../statics/StatisticsDurations";

export default {
  components: { BaseBarChartCard },
  methods: {
    ...mapActions(useStatisticsStore, [
      "getLabelsArray",
      "getDataArray",
      "fetchTPsStatistics",
    ]),
  },
  watch: {
    "TPAppsStats.TPAppsCountDuration": function () {
      this.fetchTPsStatistics()
        .then()
        .catch((err) => console.error(err));
    },
  },
  computed: {
    ...mapWritableState(useStatisticsStore, ["TPAppsStats"]),
  },
  data() {
    return {
      durations: StatisticsDurations,
    };
  },
};
</script>
