<template>
  <div v-can:[createPermission]>
    <v-btn class="ml-5" color="primary" to="/ethical-advices/schedule"
      ><v-icon>mdi-calendar-clock</v-icon>جدولة النصائح الأخلاقية</v-btn
    >
  </div>
</template>

<script>
import { WRITE_ETHICAL_ADVICES_PERMISSION } from "@/core/statics/Permissions";

export default {
  data() {
    return {
      createPermission: WRITE_ETHICAL_ADVICES_PERMISSION,
    };
  },
};
</script>
