<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> مستخدمي المنصة </template>
      <template v-slot:dataTable>
        <UsersTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import UsersTable from "../components/UsersTable.vue";
  
  export default {
    data() {
      return {
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "مستخدمي المنصة",
            disabled: true,
          },
        ],
      };
    },
    components: {
      UsersTable,
    },
  };
  </script>
  