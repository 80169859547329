<template>
  <BaseCRUDPageLayout :links="links">
    <v-container>
      <v-row v-if="!fetchingData" align="start">
        <v-col cols="12" sm="9" order="2" order-sm="1"
          ><JobDetails :job="job"
        /></v-col>
        <v-col align="start" cols="12" sm="3" order="1" order-sm="2">
          <AcceptJobButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <RejectJobButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <ShowJobApplicantsButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <CloseJobButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <ShowStatusChangeRequestsButton
            :item="job"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import JobDetails from "../components/JobDetails.vue";
import CloseJobButton from "../components/action_buttons/job_actions/CloseJobButton.vue";
import ShowJobApplicantsButton from "../components/action_buttons/job_actions/ShowJobApplicantsButton.vue";
import AcceptJobButton from "../components/action_buttons/job_actions/AcceptJobButton.vue";
import RejectJobButton from "../components/action_buttons/job_actions/RejectJobButton.vue";
import ShowStatusChangeRequestsButton from "../components/action_buttons/job_actions/ShowStatusChangeRequestsButton.vue";
import { mapActions, mapState } from "pinia";
import { useJobOpportunityStore } from "../store/JobOpportunityStore";

export default {
  mounted() {
    var jobId = this.$route.params.id;
    this.fetchJob(jobId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useJobOpportunityStore, ["job"]),
  },
  methods: {
    ...mapActions(useJobOpportunityStore, ["fetchJob"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "فرصة العمل رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: {
    JobDetails,
    ShowJobApplicantsButton,
    CloseJobButton,
    AcceptJobButton,
    RejectJobButton,
    ShowStatusChangeRequestsButton,
  },
};
</script>
