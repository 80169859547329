<template>
    <BaseTableItemAction
      v-if="item.status == 'pending'"
      v-can:[rejectPermission]
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-cancel"
      actionName="رفض نشر الفرصة"
      @click="openRejectJobDialog()"
    >
      <RejectJobConfirmationDialog
        :showCondition="showRejectDialog"
        :item="item"
        @closed="closeRejectJobDialog()"
      />
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";
  import RejectJobConfirmationDialog from "../../dialogs/job_dialogs/RejectJobConfirmationDialog.vue";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        rejectPermission: WRITE_JOBS_PERMISSION,
        showRejectDialog: false,
      };
    },
    methods: {
      openRejectJobDialog() {
        this.showRejectDialog = true;
      },
      closeRejectJobDialog() {
        this.showRejectDialog = false;
      },
    },
    components: { RejectJobConfirmationDialog },
  };
  </script>
  