//this object lists the JobOpportunity object keys and returns the job keys according to server side
export default {
  id: "id",
  label: "label",
  placeholder: "placeholder",
  note: "note",
  stepNumber: "step_number",
  isRequired: "is_required",
  isActivated: "is_active",
  type: "type",
  answers: "answers",
  createdAt: "created_at",
  order: "order",
};
