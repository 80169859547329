<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> إضافة خبر جديد </template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <CreateNewsFormVue />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import CreateNewsFormVue from "../components/forms/CreateNewsForm.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "الأخبار",
          href: "/news",
          disabled: false,
        },
        {
          text: "إضافة خبر جديد",
          disabled: true,
        },
      ],
    };
  },
  components: { CreateNewsFormVue },
};
</script>

<style></style>
