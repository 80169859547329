<template>
  <div class="bg">
    <router-view />
    <LoadingProgress></LoadingProgress>
    <NotificationAlert></NotificationAlert>
  </div>
</template>
<script>
import LoadingProgress from "../components/loader/LoadingProgress";
import NotificationAlert from "../components/notifications/NotificationAlert";

export default {
  components: {
    NotificationAlert,
    LoadingProgress,
  },
};
</script>

<style scoped>
.bg {
  background:  url("@/assets/decorations/bg.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
}
</style>
