import { defineStore } from "pinia";
import { useAdminsStore } from "./AdminsStore";

import Admin from "../models/Admin";
import BaseStore from "@/core/classes/base/BaseStore";
import AdminsService from "../services/AdminsService";

export const useAdminStore = defineStore("adminStore", {
  state: () => ({
    admin: new Admin(),
  }),
  getters: {
    allAdmins() {
      const adminsStore = useAdminsStore();
      return adminsStore.paginatedAdmins.collection;
    },
  },
  actions: {
    fetchAdmin(id) {
      return BaseStore.promiseHandler(
        () => AdminsService.getAdmin(id),
        (data) => {
          this.admin = new Admin(data.data);
        }
      );
    },
    deleteAdmin(id) {
      return BaseStore.promiseHandler(
        () => AdminsService.deleteAdmin(id),
        () => {
          this.allAdmins.splice(
            this.allAdmins.findIndex((admin) => admin.id === id),
            1
          );
        }
      );
    },
    createAdmin(data) {
      return BaseStore.promiseHandler(() => AdminsService.createAdmin(data));
    },
    editAdmin(id, data) {
      return BaseStore.promiseHandler(() => AdminsService.editAdmin(id, data));
    },
  },
});
