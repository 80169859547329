import BaseService from "@/core/classes/base/BaseService";
import JobPublisher from "../models/JobPublisher";
import Client from "@/core/classes/client/Client";

import {
  getJobPublisherAPI,
  getJobPublishersAPI,
  createJobPublishersAPI,
  toggleJobPublisherBlockStatusAPI,
} from "./JobPublishers.api";

export default class JobPublishersService extends BaseService {
  static getJobPublishers(params) {
    return Client.get(getJobPublishersAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getJobPublisher(id) {
    return Client.get(getJobPublisherAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static createJobPublisher(data) {
    return Client.post(
      createJobPublishersAPI,
      JobPublisher.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
  static toggleJobPublisherBlockStatus(id) {
    return Client.put(toggleJobPublisherBlockStatusAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
}
