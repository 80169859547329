<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedFAQs"
    :show-expand="true"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <EditFAQButton :item="item" />
      <DeleteFAQButton :item="item" />
    </template>
    <template v-slot:expansion="{ item }">
      الإجابة:{{ item.answer }}
    </template>
    <template v-slot:[`item.category`]="{ item }">
      {{ item.tagName }}
    </template>
    <template v-slot:[`item.adderName`]="{ item }">
      <RouterLink v-if="hasPermission(showAdminPermission)" :to="'/admins/'+item.adderId">
        {{ item.adderName }}
      </RouterLink>
      <label v-else> {{ item.adderName }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useFAQsStore } from "../store/FAQsStore";
import EditFAQButton from "./action_buttons/EditFAQButton.vue";
import DeleteFAQButton from "./action_buttons/DeleteFAQButton.vue";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_ADMINS_PERMISSION } from "@/core/statics/Permissions";

export default {
  mixins:[PermissionManagerMixin],
  computed: {
    ...mapState(useFAQsStore, ["paginatedFAQs"]),
    ...mapWritableState(useFAQsStore,["searchKey"]),
  },
  data() {
    return {
      showAdminPermission:READ_ADMINS_PERMISSION,
      //TODO make sure what cols are sortable
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "السؤال", width: 275, value: "question" },
        { text: "التصنيف", value: "category" },
        { text: "أضافه", value: "adderName" },
        { text: "تاريخ الإضافة", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchFAQs()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useFAQsStore, [
      "fetchFAQs",
      "setTableOptionsNFetch",
      "setSearchNFetch",
    ]),
  },
  components: {
    EditFAQButton,
    DeleteFAQButton,
    RouterLink
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
