<template>
    <BaseCRUDPageLayout :links="links">
      <v-container v-if="!fetchingData">
        <v-row>
          <v-col cols="12">
            <ApplicationDetails :application="application"
          /></v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState, mapActions } from "pinia";
  import ApplicationDetails from "../components/ApplicationDetails.vue";
  import { useTrainingProgramApplicationsStore } from "../store/TrainingProgramApplicationsStore";
  
  export default {
    mounted() {
      var applicationId = this.$route.params.id;
      this.fetchApplication(applicationId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useTrainingProgramApplicationsStore, ["application"]),
    },
    methods: {
      ...mapActions(useTrainingProgramApplicationsStore, ["fetchApplication"]),
    },
    data() {
      return {
        fetchingData: true,
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "طلبات المنح التدريبية",
            href: "/training-program-applications",
            disabled: false,
          },
          {
            text: "تفاصيل الطلب رقم " + this.$route.params.id,
            disabled: true,
          },
        ],
      };
    },
    components: { ApplicationDetails },
  };
  </script>
  