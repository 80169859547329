import { defineStore } from "pinia";
import BaseStore from "@/core/classes/base/BaseStore";
import AdminsService from "../services/AdminsService";
import AdminKeyTransformations from "../models/AdminKeyTransformations";
import PaginatedAdminCollection from "../models/PaginatedAdminCollection";

export const useAdminsStore = defineStore("adminsStore", {
  state: () => ({
    paginatedAdmins: new PaginatedAdminCollection(),
    adminsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.adminsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                AdminKeyTransformations[this.adminsTableOptions.sortBy[0]],
              sort_desc: this.adminsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.adminsTableOptions.page,
        ...sortObj,
        items_per_page: this.adminsTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.adminsTableOptions = options;
      this.fetchAdmins();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedAdmins();
      this.fetchAdmins();
    },
    resetPaginatedAdmins() {
      this.paginatedAdmins = new PaginatedAdminCollection();
    },
    fetchAdmins() {
      return BaseStore.promiseHandler(
        () => AdminsService.getAdmins(this.getSearchPageParameters),
        (data) => {
          this.paginatedAdmins = new PaginatedAdminCollection(data);
        }
      );
    },
  },
});
