<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <BaseTextField
            label="نص السؤال"
            placeholder="نص السؤال"
            v-model="question.label"
            :readonly="readOnly"
            :rules="[requiredRule]"
          ></BaseTextField>

          <BaseSelect
            v-model="question.type"
            chips
            clearable
            :readonly="readOnly"
            item-value="value"
            :items="questionTypes"
            label="نمط السؤال"
            placeholder="نمط السؤال"
            :rules="[requiredRule]"
          >
            <template v-slot:item="{ item }">
              {{ item.label }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.label }}</v-chip>
            </template>
          </BaseSelect>

          <BaseCombobox
            v-if="question.type == 'radio' || question.type == 'selection'"
            outlined
            :readonly="readOnly"
            v-model="question.answers"
            :items="question.answers"
            label="الخيارات المحتملة للإجابة"
            placeholder="الخيارات المحتملة للإجابة"
            hint="ضع عنوان الخيار ثم انقر enter"
            clearable
            multiple
            :rules="[requiredRule]"
          >
          </BaseCombobox>

          <BaseSelect
            v-model="question.stepNumber"
            chips
            clearable
            :readonly="readOnly"
            item-value="value"
            :items="stepsOptions"
            label="الخطوة التي سيعرض ضمنها السؤال"
            placeholder="الخطوة التي سيعرض ضمنها السؤال"
            :rules="[requiredRule]"
          >
            <template v-slot:item="{ item }">
              {{ item.label }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.label }}</v-chip>
            </template>
          </BaseSelect>

          <BaseTextField
            textarea
            :readonly="readOnly"
            label="ملاحظة السؤال"
            placeholder="ملاحظة السؤال"
            hint="تظهر الملاحظة تحت الحقل"
            v-model="question.note"
          ></BaseTextField>

          <BaseTextField
            textarea
            :readonly="readOnly"
            label="تلميح السؤال"
            placeholder="تلميح السؤال"
            hint="يظهر التلميح ضمن الحقل"
            v-model="question.placeholder"
          ></BaseTextField>

          <v-checkbox
            :readonly="readOnly"
            label="جعل الإجابة إجبارية"
            v-model="question.isRequired"
          ></v-checkbox>

          <v-row v-if="!readOnly" align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="emitSubmission()"
            >
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { EventBus } from "@/main";
import { questionTypes, stepsOptions } from "../statics/QuestionsOptions";

export default {
  props: {
    questionObject: Object,
    formActionName: String,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      question: this.questionObject,
      stepsOptions: stepsOptions,
      questionTypes: questionTypes,
    };
  },
  watch: {
    questionObject: {
      immediate: true,
      handler() {
        this.question = this.questionObject;
      },
    },
    question: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  methods: {
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("questionSubmitted", this.question);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>
