import BaseService from "@/core/classes/base/BaseService";
import {
  acceptJobAPI,
  rejectJobAPI,
  closeJobAPI,
  getExportJobsAPI,
  getJobAPI,
  getJobsAPI,
  getJobStatusChangeRequestsAPI,
  getJobApplicantAPI,
  getJobApplicantsAPI,
  passJobApplicantAPI,
  rejectJobApplicantAPI,
  getApplicantsFeedbackAPI,
  getPublishersFeedbackAPI
} from "./JobOpportunities.api";
import Client from "@/core/classes/client/Client";

export default class JobOpportunitiesService extends BaseService {
  static getPaginatedJobOpportunities(params) {
    return Client.get(getJobsAPI, {...params,is_paginated:1}).then((res) => Promise.resolve(res));
  }
  static getExportJobs(startDate=null,endDate=null) {
    return Client.get(getExportJobsAPI,{start_date:startDate,end_date:endDate}).then((res) => Promise.resolve(res));
  }
  static getJobOpportunity(id) {
    return Client.get(getJobAPI(id)).then((res) => Promise.resolve(res));
  }
  static getJobStatusChangeRequests(id,params) {
    return Client.get(getJobStatusChangeRequestsAPI(id),params).then((res) => Promise.resolve(res));
  }
  static acceptJob(id) {
    return Client.put(acceptJobAPI(id),{}).then((res) => Promise.resolve(res));
  }
  static rejectJob(id) {
    return Client.put(rejectJobAPI(id),{}).then((res) => Promise.resolve(res));
  }
  static closeJob(id) {
    return Client.put(closeJobAPI(id),{}).then((res) => Promise.resolve(res));
  }
  //applicants
  static getJobApplicants(jobId,params) {
    return Client.get(getJobApplicantsAPI(jobId), params).then((res) => Promise.resolve(res));
  }
  static getJobApplicant(applicantId) {
    return Client.get(getJobApplicantAPI(applicantId)).then((res) => Promise.resolve(res));
  }
  static passJobApplicant(applicantId) {
    return Client.put(passJobApplicantAPI(applicantId),{}).then((res) => Promise.resolve(res));
  }
  static rejectJobApplicant(applicantId) {
    return Client.put(rejectJobApplicantAPI(applicantId),{}).then((res) => Promise.resolve(res));
  }
  //feedback
  static getApplicantsFeedback(params) {
    return Client.get(getApplicantsFeedbackAPI, params).then((res) => Promise.resolve(res));
  }
  static getPublishersFeedback(params) {
    return Client.get(getPublishersFeedbackAPI, params).then((res) => Promise.resolve(res));
  }
}
