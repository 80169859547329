<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="trainingProgram.name"
            label="اسم المسار"
            placeholder="اسم المسار"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextArea
            v-model="trainingProgram.programConditions"
            label="شروط المسار"
            placeholder="شروط المسار"
            persistent-placeholder
          ></BaseTextArea>

          <v-file-input
            v-model="video"
            :rules="[requiredRule]"
            accept="video/mp4"
            prepend-inner-icon="mdi-video"
            prepend-icon=""
            label="الفيديو التعريفي"
            placeholder="الفيديو التعريفي"
            persistent-placeholder
            outlined
            clearable
            @change="
              () => {
                this.isVideoChanged = true;
              }
            "
          >
            <template v-slot:selection>
              <v-chip> 1 ملف  </v-chip>
            </template>
          </v-file-input>

          <v-row align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="emitSubmission()"
            >
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

import { EventBus } from "@/main";

export default {
  props: {
    trainingProgramObject: Object,
    formActionName: String,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      video: this.isEdit ? new File([],"video") : null,
      trainingProgram: this.trainingProgramObject,
      isVideoChanged: false,
    };
  },
  watch: {
    trainingProgramObject: {
      immediate: true,
      handler() {
        this.trainingProgram = this.trainingProgramObject;
      },
    },
    trainingProgram: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  methods: {
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        if (!this.isVideoChanged) this.video = null;
        this.$emit(
          "trainingProgramSubmitted",
          this.trainingProgram,
          this.video
        );
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>
