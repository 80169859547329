import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import ApplicationKeyTransformations from "../models/key_transformations/ApplicationKeyTransformations";
import PaginatedJobApplicationsCollection from "../models/paginated_collections/PaginatedJobApplicationsCollection";

export const useJobOpportunitiesFeedbackStore = defineStore(
  "jobOpportunitiesFeedbackStore",
  {
    state: () => ({
      //applicants' feedback
      paginatedApplicantsFeedback: new PaginatedJobApplicationsCollection(),
      applicantsFeedbackTableOptions: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      applicantsFeedbackSearchKey: null,
      //publisher' feedback
      paginatedPublishersFeedback: new PaginatedJobApplicationsCollection(),
      publishersFeedbackTableOption: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      publishersFeedbackSearchKey: null,
    }),
    getters: {
      getApplicantsFeedbackSearchNSortPageParameters() {
        //prepare sort object in case sort applied (and transform sort cols name)
        var sortObj =
          this.applicantsFeedbackTableOptions.sortBy.length != 0
            ? {
                sort_by:
                  ApplicationKeyTransformations[
                    this.applicantsFeedbackTableOptions.sortBy[0]
                  ],
                sort_desc: this.applicantsFeedbackTableOptions.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.applicantsFeedbackTableOptions.page,
          ...sortObj,
          items_per_page: this.applicantsFeedbackTableOptions.itemsPerPage,
          search_key: this.applicantsFeedbackSearchKey,
        });
      },
      getPublishersFeedbackSearchNSortPageParameters() {
        //prepare sort object in case sort applied (and transform sort cols name)
        var sortObj =
          this.publishersFeedbackTableOption.sortBy.length != 0
            ? {
                sort_by:
                  ApplicationKeyTransformations[
                    this.publishersFeedbackTableOption.sortBy[0]
                  ],
                sort_desc: this.publishersFeedbackTableOption.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.publishersFeedbackTableOption.page,
          ...sortObj,
          items_per_page: this.publishersFeedbackTableOption.itemsPerPage,
          search_key: this.publishersFeedbackSearchKey,
        });
      },
    },
    actions: {
      //applicants feedback actions
      setApplicantsFeedbackTableOptionsNFetch(options) {
        (this.applicantsFeedbackTableOptions = options),
          this.fetchApplicantsFeedback();
      },
      setApplicantsFeedbackSearchNFetch(searchKey) {
        this.applicantsFeedbackSearchKey = searchKey;
        this.resetPaginatedApplicantsFeedback();
        this.fetchApplicantsFeedback();
      },
      resetPaginatedApplicantsFeedback() {
        this.paginatedApplicantsFeedback =
          new PaginatedJobApplicationsCollection();
      },
      fetchApplicantsFeedback() {
        return BaseStore.promiseHandler(
          () =>
            JobOpportunitiesService.getApplicantsFeedback(
              this.getApplicantsFeedbackSearchNSortPageParameters
            ),
          (data) => {
            this.paginatedApplicantsFeedback =
              new PaginatedJobApplicationsCollection(data);
          }
        );
      },
      //publishers feedback actions
      setPublishersFeedbackTableOptionsNFetch(options) {
        (this.publishersFeedbackTableOption = options),
          this.fetchPublishersFeedback();
      },
      setPublishersFeedbackSearchNFetch(searchKey) {
        this.publishersFeedbackSearchKey = searchKey;
        this.resetPaginatedPublishersFeedback();
        this.fetchPublishersFeedback();
      },
      resetPaginatedPublishersFeedback() {
        this.paginatedPublishersFeedback =
          new PaginatedJobApplicationsCollection();
      },
      fetchPublishersFeedback() {
        return BaseStore.promiseHandler(
          () =>
            JobOpportunitiesService.getPublishersFeedback(
              this.getPublishersFeedbackSearchNSortPageParameters
            ),
          (data) => {
            this.paginatedPublishersFeedback =
              new PaginatedJobApplicationsCollection(data);
          }
        );
      },
    },
  }
);
