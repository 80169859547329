<template>
  <v-container v-can:[showPermission]>
    <v-row>
      <v-col cols="10" md="12" align="center">
        <v-row>
          <h3 class="mb-5 secondary--text">
            لمحة عن طلبات التقدم على البرامج التدريبية
            <span class="mr-2 text-subtitle-2 primary--text">
              (قد لا تعكس الإحصاءات التالية بالضرورة نشاط المنصة في آخر 24 ساعة)
            </span>
          </h3>
        </v-row>
        <v-row justify="space-around">
          <TPApplicationsCountPerDurationCard />
          <TPApplicationsCountPerTPCard />
          <TPApplicationsTotalCountCard />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TPApplicationsCountPerDurationCard from "./statistics_cards/tps/TPApplicationsCountPerDurationCard.vue";
import TPApplicationsCountPerTPCard from "./statistics_cards/tps/TPApplicationsCountPerTPCard.vue";
import { READ_TP_APPLICATIONS_PERMISSION } from "@/core/statics/Permissions";
import { mapActions } from "pinia";
import { useStatisticsStore } from "../store/StatisticsStore";
import TPApplicationsTotalCountCard from "./statistics_cards/tps/TPApplicationsTotalCountCard.vue";
import { StatisticsType } from "./statics/StatisticsTypes";

export default {
  components: {
    TPApplicationsCountPerTPCard,
    TPApplicationsCountPerDurationCard,
    TPApplicationsTotalCountCard,
  },
  mounted() {
    this.fetchStatistics(StatisticsType.TPsStatistics)
      ?.then()
      ?.catch((err) => console.error(err));
  },
  data() {
    return {
      showPermission: READ_TP_APPLICATIONS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useStatisticsStore, ["fetchStatistics"]),
  },
};
</script>
