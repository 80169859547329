<template>
  <div>
    <v-btn @click="openFilterDialog" color="accent">
      <v-badge overlap dot color="white" v-if="filterApplied">
        <v-icon>mdi-filter</v-icon>
      </v-badge>
      <v-icon v-else color="white">mdi-filter</v-icon>
      تصفية الطلبات
    </v-btn>
    <FilterApplicationsDialog
      :showCondition="showFilterDialog"
      @closed="closeFilterDialog"
    />
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useTrainingProgramApplicationsStore } from "../../store/TrainingProgramApplicationsStore";
import FilterApplicationsDialog from "../dialogs/FilterApplicationsDialog.vue";

export default {
  computed: {
    ...mapState(useTrainingProgramApplicationsStore, ["filterApplied"]),
  },
  data() {
    return {
      showFilterDialog:false,
    };
  },
  methods: {
    openFilterDialog() {
      this.showFilterDialog = true;
    },
    closeFilterDialog() {
      this.showFilterDialog = false;
    },
  },
  components: { FilterApplicationsDialog },
};
</script>
