export default {
  id: "id",
  status: "status",
  rate: "rate",
  rateContent: "rate_content",
  jobFeedback:"job_feedback",
  isOrganizationGraduate: "is_organization_graduate",
  createdAt:"created_at",
  "applicant.name": "users.name",
  "applicant.email": "users.email",
  "applicant.phoneNumber": "users.phone_number",
  "job.id": "job_id",
  "job.title": "jobs.title",
  "job.publisher.name": "job_publishers.name",
};
