import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import News from "./News";

export default class PaginatedNewsCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((news) => {
      this.collection.push(new News(news));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((news) => {
      this.collection.push(new News(news));
    });
  }
}
