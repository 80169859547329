<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تعديل مسار </template>
    <v-container>
      <v-row>
        <v-col v-if="!fetchingData" cols="12">
          <EditTrainingProgramForm :trainingProgram="trainingProgram" />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useTrainingProgramStore } from "../store/TrainingProgramStore";
import EditTrainingProgramForm from "../components/forms/EditTrainingProgramForm.vue";

export default {
  mounted() {
    var tpId = this.$route.params.id;
    this.fetchTrainingProgram(tpId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useTrainingProgramStore, ["trainingProgram"]),
  },
  methods: {
    ...mapActions(useTrainingProgramStore, ["fetchTrainingProgram"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "مسارات المنح التدريبية",
          href: "/training-programs",
          disabled: false,
        },
        {
          text: "تعديل المسار رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { EditTrainingProgramForm },
};
</script>
