<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> الآراء المرسلة عن المنصة</template>
      <template v-slot:dataTable>
        <PlatformFeedbackTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import PlatformFeedbackTable from "../components/PlatformFeedbackTable.vue";

  export default {
    data() {
      return {
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "آراء المنصة",
            disabled: true,
          },
        ],
      };
    },
    components: { PlatformFeedbackTable },
  };
  </script>
  