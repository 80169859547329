import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import {
  READ_TP_APPLICATION_QUESTIONS_PERMISSION,
  WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
} from "@/core/statics/Permissions";
import TrainingProgramApplicationQuestionsPage from "../pages/TrainingProgramApplicationQuestionsPage.vue";
import TrainingProgramApplicationQuestionPage from "../pages/TrainingProgramApplicationQuestionPage.vue";
import CreateTrainingProgramApplicationQuestionPage from "../pages/CreateTrainingProgramApplicationQuestionPage.vue";
import EditTrainingProgramApplicationQuestionPage from "../pages/EditTrainingProgramApplicationQuestionPage.vue";
import SortTrainingProgramApplicationQuestionsPageVue from "../pages/SortTrainingProgramApplicationQuestionsPage.vue";

export default {
  path: "/training-program-application-questions",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "training_program_application_questions",
      component: TrainingProgramApplicationQuestionsPage,
      meta: {
        title: "أسئلة التقدم على منحة تدريبية",
        requiredPermission: READ_TP_APPLICATION_QUESTIONS_PERMISSION,
      },
    },
    {
      path: "/training-program-application-questions/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          component: CreateTrainingProgramApplicationQuestionPage,
          name: "create_training_program_application_question",
          meta: {
            title: "إنشاء سؤال ",
            requiredPermission: WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/training-program-application-questions/sort",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          component: SortTrainingProgramApplicationQuestionsPageVue,
          name: "sort_training_program_application_question",
          meta: {
            title: "ترتيب ظهور الأسئلة",
            requiredPermission: WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/training-program-application-questions/:id",
      component: TrainingProgramApplicationQuestionPage,
      name: "training_program_application_question",
      meta: {
        title: "تفاصيل سؤال تقدم للمنحة التدريبية ",
        requiredPermission: READ_TP_APPLICATION_QUESTIONS_PERMISSION,
      },
    },
    {
      path: "/training-program-application-questions/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_training_program_application_question",
          component: EditTrainingProgramApplicationQuestionPage,
          meta: {
            title: "تعديل سؤال",
            requiredPermission: WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
          },
        },
      ],
    },
  ],
};
