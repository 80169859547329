import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import CareerAptitudeTestQuestion from "./CareerAptitudeTestQuestion";

export default class PaginatedCareerAptitudeTestQuestionsCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((q) => {
      this.collection.push(new CareerAptitudeTestQuestion(q));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((q) => {
      this.collection.push(new CareerAptitudeTestQuestion(q));
    });
  }
}
