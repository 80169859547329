<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> أسئلة التقدم على منحة تدريبية </template>
    <template v-slot:tableActions>
      <SortTPApplicationQuestionsButton class="mr-5" />
      <CreateTPApplicationQuestionButton class="mr-5" />
    </template>
    <template v-slot:dataTable>
      <TPApplicationQuestionsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import TPApplicationQuestionsTable from "../components/TPApplicationQuestionsTable.vue";
import CreateTPApplicationQuestionButton from "../components/action_buttons/CreateTPApplicationQuestionButton.vue";
import SortTPApplicationQuestionsButton from "../components/action_buttons/SortTPApplicationQuestionsButton.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "أسئلة التقدم على منحة تدريبية",
          disabled: true,
        },
      ],
    };
  },
  components: {
    TPApplicationQuestionsTable,
    CreateTPApplicationQuestionButton,
    SortTPApplicationQuestionsButton,
  },
};
</script>
