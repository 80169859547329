import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import User from "./User";

export default class PaginatedUserCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((user) => {
      this.collection.push(new User(user));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((user) => {
      this.collection.push(new User(user));
    });
  }
}
