import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgramQuestionKeyTransformations from "../models/TrainingProgramQuestionKeyTransformations";
import PaginatedTrainingProgramQuestionCollection from "../models/PaginatedTrainingProgramQuestionCollection";
import TrainingProgramApplicationQuestionsService from "../services/TrainingProgramApplicationQuestionsService";
import TrainingProgramQuestion from "../models/TrainingProgramQuestion";

export const useTrainingProgramQuestionsStore = defineStore(
  "trainingProgramQuestionsStore",
  {
    state: () => ({
      paginatedQuestions: new PaginatedTrainingProgramQuestionCollection(),
      questionTableOptions: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      searchKey: null,
      sortedQuestions: [],
    }),
    getters: {
      getSearchPageParameters() {
        //prepare sort object in case sort applied (and transform sort cols name)
        var sortObj =
          this.questionTableOptions.sortBy.length != 0
            ? {
                sort_by:
                  TrainingProgramQuestionKeyTransformations[
                    this.questionTableOptions.sortBy[0]
                  ],
                sort_desc: this.questionTableOptions.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.questionTableOptions.page,
          ...sortObj,
          items_per_page: this.questionTableOptions.itemsPerPage,
          search_key: this.searchKey,
        });
      },
    },
    actions: {
      setTableOptionsNFetch(options) {
        this.questionTableOptions = options;
        this.fetchQuestions();
      },
      setSearchNFetch(searchKey) {
        this.searchKey = searchKey;
        this.resetPaginatedQuestions();
        this.fetchQuestions();
      },
      resetPaginatedQuestions() {
        this.paginatedQuestions =
          new PaginatedTrainingProgramQuestionCollection();
      },
      fetchQuestions() {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationQuestionsService.getTPApplicationQuestions(
              this.getSearchPageParameters
            ),
          (data) => {
            this.paginatedQuestions =
              new PaginatedTrainingProgramQuestionCollection(data);
          }
        );
      },
      fetchSortedQuestions() {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationQuestionsService.getSortedTPApplicationQuestions(),
          (data) => {
            this.sortedQuestions=[];
            data?.data?.map((question) => {
              this.sortedQuestions.push(new TrainingProgramQuestion(question));
            });
          }
        );
      },
      sendNewSortedTPQuestions(newSortedQs) {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationQuestionsService.sendSortedTPApplicationQuestions(
              newSortedQs
            ),
          () => {}
        );
      },
    },
  }
);
