import { defineStore } from "pinia";

import NewsServices from "../services/NewsServices";
import BaseStore from "@/core/classes/base/BaseStore";
import NewsKeyTransformations from "../models/NewsKeyTransformations";
import PaginatedNewsCollection from "../models/PaginatedNewsCollection";

export const usePaginatedNewsStore = defineStore("paginatedNewsStore", {
  state: () => ({
    paginatedNews: new PaginatedNewsCollection(),
    newsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getSearchPageParameters() {
      var sortObj =
        this.newsTableOptions.sortBy.length != 0
          ? {
              sort_by: NewsKeyTransformations[this.newsTableOptions.sortBy[0]],
              sort_desc: this.newsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.newsTableOptions.page,
        ...sortObj,
        items_per_page: this.newsTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.newsTableOptions = options;
      this.fetchNews();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedNews();
      this.fetchNews();
    },
    resetPaginatedNews() {
      this.paginatedNews = new PaginatedNewsCollection();
    },
    fetchNews() {
      return BaseStore.promiseHandler(
        () => NewsServices.getPaginatedNews(this.getSearchPageParameters),
        (data) => {
          this.paginatedNews = new PaginatedNewsCollection(data);
        }
      );
    },
  },
});
