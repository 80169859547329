<template>
  <v-container v-can:[showPermission]>
    <v-row>
      <v-col cols="10" md="12" align="center">
        <v-row>
          <h3 class="mb-5 secondary--text">
            لمحة عن فرص العمل المنشورة
            <span
              class="mr-2 text-subtitle-2 primary--text"
            >
              (قد لا تعكس الإحصاءات التالية بالضرورة نشاط المنصة في آخر 24 ساعة)
            </span>
          </h3>
        </v-row>
        <v-row justify="space-around">
          <JobsCountPerDurationCard />
          <JobsApplicationsCountPerDurationCard />
          <JobsTotalCountCard />
          <JobsApplicationsTotalCountCard />
          <JobsFeedbackCountPerDurationCard />
          <ApplicantsRateCountPerRateCard />
          <JobsClosingReasonsCountCard />
          <JobsRenewalReasonsCountCard />
          <FrequentJobFieldsCard />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";
import { mapActions } from "pinia";
import { StatisticsType } from "./statics/StatisticsTypes";
import { useStatisticsStore } from "../store/StatisticsStore";
import ApplicantsRateCountPerRateCard from "./statistics_cards/jobs/ApplicantsRateCountPerRateCard.vue";
import JobsApplicationsCountPerDurationCard from "./statistics_cards/jobs/JobsApplicationsCountPerDurationCard.vue";
import JobsApplicationsTotalCountCard from "./statistics_cards/jobs/JobsApplicationsTotalCountCard.vue";
import JobsClosingReasonsCountCard from "./statistics_cards/jobs/JobsClosingReasonsCountCard.vue";
import JobsCountPerDurationCard from "./statistics_cards/jobs/JobsCountPerDurationCard.vue";
import JobsFeedbackCountPerDurationCard from "./statistics_cards/jobs/JobsFeedbackCountPerDurationCard.vue";
import JobsRenewalReasonsCountCard from "./statistics_cards/jobs/JobsRenewalReasonsCountCard.vue";
import JobsTotalCountCard from "./statistics_cards/jobs/JobsTotalCountCard.vue";
import FrequentJobFieldsCard from "./statistics_cards/jobs/FrequentJobFieldsCard.vue";

export default {
  components: {
    JobsCountPerDurationCard,
    JobsTotalCountCard,
    JobsApplicationsCountPerDurationCard,
    JobsApplicationsTotalCountCard,
    JobsFeedbackCountPerDurationCard,
    ApplicantsRateCountPerRateCard,
    JobsClosingReasonsCountCard,
    JobsRenewalReasonsCountCard,
    FrequentJobFieldsCard,
  },
  mounted() {
    this.fetchStatistics(StatisticsType.JobsStatistics)
      ?.then()
      ?.catch((err) => console.error(err));
  },
  data() {
    return {
      showPermission: READ_JOBS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useStatisticsStore, ["fetchStatistics"]),
  },
};
</script>
