<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedProjects"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ViewProjectButton :item="item" />
      <EditProjectButton :item="item" />
      <DeleteProjectButton :item="item" />
    </template>
    <template v-slot:[`item.adderName`]="{ item }">
      <RouterLink
        v-if="hasPermission(showAdminPermission)"
        :to="'/admins/' + item.adderId"
      >
        {{ item.adderName }}
      </RouterLink>
      <label v-else> {{ item.adderName }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { RouterLink } from "vue-router";
import { useProjectsStore } from "../store/ProjectsStore";
import {
  READ_ADMINS_PERMISSION,
  READ_USERS_PERMISSION,
} from "@/core/statics/Permissions";

import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import DeleteProjectButton from "./action_buttons/DeleteProjectButton.vue";
import ViewProjectButton from "./action_buttons/ViewProjectButton.vue";
import EditProjectButton from "./action_buttons/EditProjectButton.vue";

export default {
  mixins: [PermissionManagerMixin],
  components: {
    DeleteProjectButton,
    ViewProjectButton,
    EditProjectButton,
    RouterLink,
  },
  computed: {
    ...mapState(useProjectsStore, ["paginatedProjects"]),
    ...mapWritableState(useProjectsStore,["searchKey"]),
  },
  data() {
    return {
      showAdminPermission: READ_ADMINS_PERMISSION,
      showUserPermission: READ_USERS_PERMISSION,
      //TODO make sure what cols are sortable
      headers: [
        { text: "الرقم", value: "id" },
        { text: "العنوان", value: "title" },
        { text: "أنجزه", value: "accomplisherName" },
        { text: "أضافه", value: "adderName" },
        { text: "تاريخ الإضافة", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchProjects()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useProjectsStore, [
      "fetchProjects",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
