export const questionsTypes = [
  {
    symbol: "R",
    type: "الواقعي",
  },
  {
    symbol: "I",
    type: "المبادر",
  },
  {
    symbol: "A",
    type: "الفنان",
  },
  {
    symbol: "S",
    type: "الاجتماعي",
  },
  {
    symbol: "E",
    type: "المستكشف",
  },
  {
    symbol: "C",
    type: "المنظم",
  },
];
