<template>
  <div>
    <div class="my-5">
      <span class="primary--text">اسم المستخدم: </span>{{ user.name }}
    </div>
    <!-- <div class="my-5">
      <span class="primary--text">الاسم المميز لحساب المستخدم: </span>{{ user.username }}
    </div> -->
    <div class="my-5">
      <span class="primary--text">رقم الهاتف: </span>{{ user.phoneNumber }}
    </div>
    <div class="my-5">
      <span class="primary--text">البريد الالكتروني: </span>{{ user.email?user.email:'لا يوجد' }}
    </div>
    <div class="my-5">
      <span class="primary--text">مجال الدراسة: </span>{{ user.studyField }}
    </div>
    <div class="my-5">
      <span class="primary--text"> تاريخ الانضمام: </span>{{ user.createdAt }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
};
</script>

<style scoped></style>
