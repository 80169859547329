export const permissionTranslationMapper = {
  "news-r": "قراءة الأخبار",
  "news-w": "تعديل الأخبار",
  "projects-r": "قراءة المشاريع المميزة",
  "projects-w": "تعديل المشاريع المميزة",
  "ethical-advices-r": "قراءة النصائح الأخلاقية",
  "ethical-advices-w": "تعديل النصائح الأخلاقية",
  "faqs-r": "قراءة الأسئلة الشائعة",
  "faqs-w": "تعديل الأسئلة الشائعة",
  "platform-feedback-r": "قراءة آراء المنصة",
  "platform-feedback-w": "تعديل آراء المنصة",
  "jobs-r": "قراءة فرص العمل",
  "jobs-w": "تعديل فرص العمل",
  "tps-r": "قراءة المسارات التدريبية",
  "tps-w": "تعديل المسارات التدريبية",
  "tp-suggestions-r": "قراءة اقتراحات المنح التدريبية",
  "tp-suggestions-w": "تعديل اقتراحات المنح التدريبية",
  "tp-application-questions-r": "قراءة أسئلة التقدم على منحة تدريبية",
  "tp-application-questions-w": "تعديل أسئلة التقدم على منحة تدريبية",
  "tp-applications-r": "قراءة طلبات التقدم للمنح التدريبية",
  "tp-applications-w": "تعديل طلبات التقدم للمنح التدريبية",
  "trainers-resumes-r":"قراءة السير الذاتية للمدربين",
  "trainers-resumes-w":"تعديل السير الذاتية للمدربين",
  "career-test-questions-r": "قراءة أسئلة اختبار الميول المهني",
  "career-test-questions-w": "تعديل أسئلة اختبار الميول المهني",
  "job-publishers-r": "قراءة ناشري فرص العمل",
  "job-publishers-w": "تعديل ناشري فرص العمل",
  "users-r": "قراءة مستخدمي المنصة ",
  "users-w": "تعديل مستخدمي المنصة",
  "admins-r": "قراءة مشرفي المنصة",
  "admins-w": "تعديل مشرفي المنصة",
};
