<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> إنشاء سؤال شائع </template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <CreateFAQForm />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import CreateFAQForm from "../components/forms/CreateFAQForm.vue";
  
  export default {
    data() {
      return {
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "الأسئلة الشائعة",
            href: "/faqs",
            disabled: false,
          },
          {
            text: "إنشاء سؤال شائع جديد",
            disabled: true,
          },
        ],
      };
    },
    components: { CreateFAQForm },
  };
  </script>
  