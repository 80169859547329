import Tag from "./Tag";
import keysTransformations from "./FAQKeyTransformations";
export default class FAQ {
  id;
  question;
  answer;
  tag;
  tagObject;
  constructor(data) {
    this.id = data?.id;
    this.question = data?.question;
    this.answer = data?.answer;
    this.tag = new Tag(data?.category);
    this.tagName = this.tag?.name;
    this.adderName = data?.added_by?.name;
    this.adderId = data?.added_by?.id;
    this.createdAt = data?.created_at;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
}
