import keysTransformations from "./EAKeyTransformations";

export default class EthicalAdvice {
  id;
  content;
  scheduledTo;
  adderName;
  constructor(data) {
    this.id = data?.id;
    this.content = data?.content;
    this.createdAt = data?.created_at;
    this.adderName = data?.added_by?.name;
    this.adderId = data?.added_by?.id;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
}
