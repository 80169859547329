<template>
  <v-card class="my-3 py-2 px-5">
    <div class="my-2 primary--text">ترتيب الحقل في التصدير: {{ exportKeys[index].order }}</div>
    <div v-if="exportKeys[index].question" class="my-2 secondary--text">السؤال : {{ exportKeys[index].question.label }}</div>
    <BaseTextField
      v-model="exportKeys[index].label"
      label="اسم الحقل عند التصدير"
      dense
      :rules="[requiredRule]"
    >
    </BaseTextField>
  </v-card>
</template>

<script>
import { mapWritableState } from "pinia";
import { useTrainingProgramApplicationsStore } from "../store/TrainingProgramApplicationsStore";
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  computed: {
    ...mapWritableState(useTrainingProgramApplicationsStore, ["exportKeys"]),
  },
  props: {
    index: Number,
  },
};
</script>
