import Notification from "../Notification";

export default class NewJobOpportunityNotification extends Notification{
    constructor(data){
        super(data);
        this.jobId=data.data.job_id;
        this.jobTitle=data.data.job_title;
    }
    getTitle(){
        return 'فرصة عمل جديدة'
    }
    getBody(){
        return `لقد تم نشر فرصة عمل جديدة، رقمها #${this.jobId} ولها المسمى الوظيفي "${this.jobTitle}" `
    }
    getActionLink(){
        return `/job-opportunities/${this.jobId}`
    }
}