import BaseService from "@/core/classes/base/BaseService";
import {
  getFAQsTagsAPI,
  getFAQsAPI,
  getFAQAPI,
  createFAQsAPI,
  editFAQAPI,
  deleteFAQAPI
} from "./FAQs.api";
import FAQ from "../models/FAQ";
import Client from "@/core/classes/client/Client";

export default class FAQsService extends BaseService {
  static getFAQsTags() {
    return Client.get(getFAQsTagsAPI).then((res) => Promise.resolve(res));
  }
  static getPaginatedFAQs(params) {
    return Client.get(getFAQsAPI,params).then((res) => Promise.resolve(res));
  }
  static getFAQ(id) {
    return Client.get(getFAQAPI(id)).then((res) => Promise.resolve(res));
  }
  static createFAQ(data) {
    return Client.post(createFAQsAPI,FAQ.transformKeysForSend(data)).then((res) => Promise.resolve(res));
  }
  static editFAQ(id,data) {
    return Client.put(editFAQAPI(id),FAQ.transformKeysForSend(data)).then((res) => Promise.resolve(res));
  }
  static deleteFAQ(id) {
    return Client.delete(deleteFAQAPI(id)).then((res) => Promise.resolve(res));
  }

}