<template>
    <BaseConfirmationDialog
      :showCondition="showCondition"
      title="تمرير المتقدم"
      confirmationMessage="هل أنت متأكد من تمرير هذا المتقدم إلى الجهة الناشرة لفرصة العمل؟"
      @closed="$emit('closed')"
    >
      <template v-slot:confirmationButton>
        <v-btn @click="confirmAction()" class="accent rounded-pill px-10">
          نعم
        </v-btn>
      </template>
    </BaseConfirmationDialog>
  </template>
  <script>
  import { mapActions } from "pinia";
  import { useJobApplicantsStore } from "../../../store/JobApplicantsStore";
  import BaseConfirmationDialog from "../BaseConfirmationDialog.vue";
  export default {
    props: {
      showCondition: Boolean,
      item: Object,
    },
    components: { BaseConfirmationDialog },
    methods: {
      ...mapActions(useJobApplicantsStore, ["passJobApplicant"]),
      confirmAction() {
        this.passJobApplicant(this.item.id)
          .then()
          .catch((err) => console.error(err));
      },
    },
  };
  </script>
  