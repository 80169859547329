<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedAdmins"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowAdminButton :item="item" />
      <EditAdminButton :item="item" />
      <DeleteAdminButton :item="item" />
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useAdminsStore } from "../store/AdminsStore";

import ShowAdminButton from "./action_buttons/ShowAdminButton.vue";
import EditAdminButton from "./action_buttons/EditAdminButton.vue";
import DeleteAdminButton from "./action_buttons/DeleteAdminButton.vue";

export default {
  computed: {
    ...mapState(useAdminsStore, ["paginatedAdmins"]),
    ...mapWritableState(useAdminsStore,["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "الاسم", value: "name" },
        { text: "البريد الالكتروني", value: "email" },
        { text: "تاريخ الإنشاء", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchAdmins()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useAdminsStore, [
      "fetchAdmins",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  components: {
    ShowAdminButton,
    EditAdminButton,
    DeleteAdminButton,
  },
  destroyed(){
    this.searchKey=null;
  }
};
</script>
