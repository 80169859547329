<template>
  <BaseCRUDPageLayout :links="links">
    <GreetingCard />
    <TPApplicationsStats v-if="hasPermission(showTPApplicationsStatsPermission)" />
    <JobsStats class="mt-5" v-if="hasPermission(showJobsStatsPermission)" />
    <UsersStats class="mt-5" v-if="hasPermission(showUsersStatsPermission)" />
    <PublishersStats class="mt-5" v-if="hasPermission(showPublishersStatsPermission)" />
  </BaseCRUDPageLayout>
</template>

<script>
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import GreetingCard from "../components/GreetingCard.vue";
import JobsStats from "../components/JobsStats.vue";
import PublishersStats from "../components/PublishersStats.vue";
import TPApplicationsStats from "../components/TPApplicationsStats.vue";
import UsersStats from "../components/UsersStats.vue";
import {
  READ_JOBS_PERMISSION,
  READ_JOB_PUBLISHERS_PERMISSION,
  READ_TP_APPLICATIONS_PERMISSION,
  READ_USERS_PERMISSION,
} from "@/core/statics/Permissions";

export default {
  mixins: [PermissionManagerMixin],
  components: {
    TPApplicationsStats,
    GreetingCard,
    JobsStats,
    UsersStats,
    PublishersStats,
  },
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          disabled: true,
        },
      ],
      showTPApplicationsStatsPermission: READ_TP_APPLICATIONS_PERMISSION,
      showJobsStatsPermission: READ_JOBS_PERMISSION,
      showUsersStatsPermission: READ_USERS_PERMISSION,
      showPublishersStatsPermission: READ_JOB_PUBLISHERS_PERMISSION,
    };
  },
};
</script>
