import BaseService from "@/core/classes/base/BaseService";
import Admin from "../models/Admin";
import Client from "@/core/classes/client/Client";
import {
  getAdminAPI,
  editAdminAPI,
  getAdminsAPI,
  deleteAdminAPI,
  createAdminAPI,
} from "./Admins.api";

export default class AdminsService extends BaseService {
  static getAdmins(params) {
    return Client.get(getAdminsAPI, params).then((res) => Promise.resolve(res));
  }
  static getAdmin(id) {
    return Client.get(getAdminAPI(id)).then((res) => Promise.resolve(res));
  }
  static createAdmin(data) {
    return Client.post(createAdminAPI, Admin.transformKeysForSend(data)).then(
      (res) => Promise.resolve(res)
    );
  }
  static editAdmin(id, data) {
    return Client.put(editAdminAPI(id), Admin.transformKeysForSend(data)).then(
      (res) => Promise.resolve(res)
    );
  }
  static deleteAdmin(id) {
    return Client.delete(deleteAdminAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
}
