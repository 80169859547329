export default class Applicant {
  id;
  name;
  email;
  phoneNumber;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.email = data?.email;
    this.phoneNumber = data?.phone_number;
  }
}
