<template>
  <v-combobox v-on="$listeners" v-bind="$attrs" outlined persistent-placeholder>
    <template v-slot:selection="{ item }">
      <slot name="selection" :item="item">
        <v-chip>{{ item }} </v-chip>
      </slot>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "BaseCombobox",
};
</script>
