<template>
  <BaseAdminForm
    :adminObject="admin"
    :isEdit="true"
    formActionName="تعديل المشرف"
    @adminSubmitted="(submittedAdmin) => sendAdminData(submittedAdmin)"
  />
</template>

<script>
import { mapActions } from "pinia";
import { useAdminStore } from "../../store/AdminStore";

import BaseAdminForm from "./BaseAdminForm.vue";

export default {
  props: {
    admin: Object,
  },
  methods: {
    ...mapActions(useAdminStore, ["editAdmin"]),
    sendAdminData(submittedAdmin) {
      this.editAdmin(this.admin.id, submittedAdmin)
        .then(() => {
          this.$router.push("/admins");
        })
        .catch();
    },
  },
  components: { BaseAdminForm },
};
</script>

<style scoped></style>
