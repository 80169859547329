<template>
  <v-navigation-drawer
    color="lighterSecondary"
    overlay-color="lightNeutral"
    v-model="isDrawerVisible"
    width="275"
    height="100%"
    app
    :right="true"
  >
    <!--drawer background-->
    <template v-slot:img>
      <v-img
        class="opacity-md"
        max-width="140"
        src="@/assets/decorations/side-drawer-cut.svg"
      >
      </v-img>
    </template>
    <!--drawer header-->
    <template v-slot:prepend>
      <v-list-item height="100" class="mt-4">
        <v-spacer></v-spacer>
        <v-img
          max-width="75"
          contain
          src="@/assets/logos/vector-logo.png"
          class="rounded"
        ></v-img>
        <v-spacer></v-spacer>
      </v-list-item>
      <v-list-item>
        <v-spacer></v-spacer>
        <h2 class="secondary--text f-s-md">
          {{ username ? username : "اسم المشرف" }}
        </h2>
        <v-spacer></v-spacer>
      </v-list-item>
    </template>
    <!--drawer tabs-->
    <v-tabs center-active background-color="transparent" vertical optional>
      <v-tab class="f-s-md justify-start" to="/">
        <v-icon class="ml-5"> mdi-home </v-icon>
        الصفحة الرئيسية
      </v-tab>
    </v-tabs>
    <v-expansion-panels flat focusable accordion>
      <!--PLATFORM CONTENT TABS-->
      <TabsExpansionPanel
        :permissionList="platformContentTabsPermissions"
        title="محتوى المنصة والآراء المرسلة لها"
        :tabs="platformContentTabs"
      />

      <!--TRAINING PROGRAMS TABS -->
      <TabsExpansionPanel
        :permissionList="trainingProgramsTabsPermissions"
        title="البرامج التدريبية"
        :tabs="trainingProgramsTabs"
      />

      <!--APTITUDE TEST TABS -->
      <TabsExpansionPanel
        :permissionList="aptitudeTestTabsPermissions"
        title="اختبار الميول المهني"
        :tabs="aptitudeTestTabs"
      />

      <!--JOBS TABS -->
      <TabsExpansionPanel
        :permissionList="jobsTabsPermissions"
        title="فرص العمل"
        :tabs="jobsTabs"
      />

      <!--USERS TABS -->
      <TabsExpansionPanel
        :permissionList="usersTabsPermissions"
        title="الحسابات والمستخدمين"
        :tabs="usersTabs"
      />
    </v-expansion-panels>

    <!--profile/logout tabs-->
    <template v-slot:append>
      <v-tabs class="mb-5" background-color="transparent" vertical optional>
        <v-tab class="f-s-md justify-start" to="/profile">
          <v-icon class="ml-5"> mdi-account-circle </v-icon>
          الملف الشخصي
        </v-tab>
        <v-tab class="f-s-md justify-start" @click="showLogoutDialog()">
          <v-icon class="ml-5"> mdi-logout </v-icon>
          تسجيل الخروج
        </v-tab>
      </v-tabs>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store";
import { useGlobalStore } from "@/store/GlobalStore";
import { mapActions, mapState, mapWritableState } from "pinia";
import {
  platformContentTabs,
  trainingProgramsTabs,
  aptitudeTestTabs,
  jobsTabs,
  usersTabs,
  platformContentTabsPermission,
  trainingProgramsTabsPermissions,
  aptitudeTestTabsPermissions,
  jobsTabsPermissions,
  usersTabsPermissions,
} from "./TabsList";
import TabsExpansionPanel from "./TabsExpansionPanel.vue";

export default {
  data() {
    return {
      platformContentTabs: platformContentTabs,
      trainingProgramsTabs: trainingProgramsTabs,
      aptitudeTestTabs: aptitudeTestTabs,
      jobsTabs: jobsTabs,
      usersTabs: usersTabs,
      platformContentTabsPermissions: platformContentTabsPermission,
      trainingProgramsTabsPermissions: trainingProgramsTabsPermissions,
      aptitudeTestTabsPermissions: aptitudeTestTabsPermissions,
      jobsTabsPermissions: jobsTabsPermissions,
      usersTabsPermissions: usersTabsPermissions,
    };
  },
  computed: {
    ...mapWritableState(useGlobalStore, ["isDrawerVisible"]),
    ...mapState(useAuthStore, ["username"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["changeLogoutDialogVisibility"]),
    showLogoutDialog() {
      this.changeLogoutDialogVisibility();
    },
  },
  components: { TabsExpansionPanel },
};
</script>

<style scoped>
.opacity-md {
  opacity: 0.5;
}
.f-s-md {
  font-size: medium;
}
.justify-start {
  justify-content: start;
}
</style>
