<template>
  <div>
    <h2 class="secondary--text">
      تفاصيل الطلب رقم {{ application.id }}
      <v-chip dark color="primary" 
        >المسار التدريبي : {{ application.trainingProgram.title }}</v-chip
      >
    </h2>

    <div v-for="(value, key) in application.content" :key="key" class="my-5">
      <span class="primary--text">{{ key }} </span>: {{ value }}
    </div>
    <h4 class="my-5 secondary--text">
     نتيجة اختبار الميول المهني: {{ application.careerAptitudeTestResult.toString() }} 
    </h4>
    <h4 class="accent--text">تم تقديمه بتاريخ {{ application.createdAt }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    application: Object,
  },
};
</script>

<style scoped></style>
