import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import PlatformFeedbackService from "../services/PlatformFeedbackService";
import PlatformFeedbackKeyTransformations from "../models/PlatformFeedbackKeyTransformations";
import PaginatedPlatformFeedbackCollection from "../models/PaginatedPlatformFeedbackCollection";

export const usePlatformFeedbackStore = defineStore("platformFeedbackStore", {
  state: () => ({
    paginatedFeedback: new PaginatedPlatformFeedbackCollection(),
    feedbackTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
  }),
  getters: {
    getFilterSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.feedbackTableOptions.sortBy.length != 0
          ? {
              sort_by:
                PlatformFeedbackKeyTransformations[
                  this.feedbackTableOptions.sortBy[0]
                ],
              sort_desc: this.feedbackTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.feedbackTableOptions.page,
        ...sortObj,
        items_per_page: this.feedbackTableOptions.itemsPerPage,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.feedbackTableOptions = options;
      this.fetchPlatformFeedback();
    },
    fetchPlatformFeedback() {
      return BaseStore.promiseHandler(
        () =>
          PlatformFeedbackService.getPaginatedFeedback(
            this.getFilterSearchPageParameters
          ),
        (data) => {
          this.paginatedFeedback = new PaginatedPlatformFeedbackCollection(
            data
          );
        }
      );
    },
  },
});
