export const questionTypes = [
  {
    label: "حقل نصي",
    value: "text_field",
  },
  {
    label: "حقل نصي طويل",
    value: "text_area",
  },
  {
    label: "قائمة خيارات",
    value: "selection",
  },
  {
    //TODO find a better translation
    label: "خيارات radio",
    value: "radio",
  },
  {
    label: "اختيار تاريخ",
    value: "date",
  },
];

export const stepsOptions = [
    {
      label: "الخطوة الأولى(المعلومات الشخصية)",
      value: 1,
    },
    {
        label: "الخطوة الثانية(الدراسة والعمل)",
        value: 2,
    },
    {
        label: "الخطوة الثالثة(نوع الاستفادة)",
        value: 3,
    },
  ];
