import Resumable from "@/core/classes/resumable/Resumable";
import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";

import {
  getProjectsAPI,
  deleteProjectAPI,
  createProjectAPI,
  editProjectAPI,
  getProjectAPI,
  uploadVideoAPI,
} from "./Projects.api";

export default class ProjectsService extends BaseService {
  static getProjects(params) {
    return Client.get(getProjectsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getProject(id) {
    return Client.get(getProjectAPI(id)).then((res) => Promise.resolve(res));
  }
  static deleteProject(id) {
    return Client.delete(deleteProjectAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static createProject(data) {
    return Client.post(createProjectAPI, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static editProject(id, data) {
    return Client.put(editProjectAPI(id), data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static uploadProjectVideos(id, data) {
    Resumable.opts.target = process.env.VUE_APP_API_BASE_URL + uploadVideoAPI(id);
    Resumable.addFiles(data);
  }
}
