import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import TrainingProgramPage from "../pages/TrainingProgramPage.vue";
import TrainingProgramsPage from "../pages/TrainingProgramsPage.vue";
import CreateTrainingProgramPage from "../pages/CreateTrainingProgramPage.vue";
import EditTrainingProgramPage from "../pages/EditTrainingProgramPage.vue";
import {
  READ_TPS_PERMISSION,
  WRITE_TPS_PERMISSION,
} from "@/core/statics/Permissions";

export default {
  path: "/training-programs",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "training_programs",
      component: TrainingProgramsPage,
      meta: {
        title: "المنح التدريبية",
        requiredPermission: READ_TPS_PERMISSION,
      },
    },
    {
      path: "/training-programs/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_training_program",
          component: EditTrainingProgramPage,
          meta: {
            title: "تعديل مسار مهني",
            requiredPermission: WRITE_TPS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/training-programs/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_training_program",
          component: CreateTrainingProgramPage,
          meta: {
            title: "إنشاء مسار مهني ",
            requiredPermission: WRITE_TPS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/training-programs/:id",
      component: TrainingProgramPage,
      name: "training_program",
      meta: {
        title: "تفاصيل مسار مهني ",
        requiredPermission: READ_TPS_PERMISSION,
      },
    },
  ],
};
