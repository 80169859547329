<template>
  <div v-can:[createPermission]>
    <v-btn color="accent" @click="openCreateDialog()"><v-icon>mdi-plus</v-icon> إضافة نصيحة أخلاقية</v-btn>
    <CreateEthicalAdviceDialog
      :showCondition="showCreateDialog"
      @closed="closeCreateDialog"
    />
  </div>
</template>

<script>
import CreateEthicalAdviceDialog from "../dialogs/CreateEthicalAdviceDialog.vue";
import { WRITE_ETHICAL_ADVICES_PERMISSION } from "@/core/statics/Permissions";

export default {
  data() {
    return {
      createPermission: WRITE_ETHICAL_ADVICES_PERMISSION,
      showCreateDialog: false,
    };
  },
  methods: {
    openCreateDialog() {
      this.showCreateDialog = true;
    },
    closeCreateDialog() {
      this.showCreateDialog = false;
    },
  },
  components: { CreateEthicalAdviceDialog },
};
</script>
