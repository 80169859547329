export default class JobPublisher{
    constructor(data){
        this.id=data?.id;
        this.name=data?.name;
        this.field=data?.field;
        this.location=data?.location;
        this.logoURL=data?.logo?process.env.VUE_APP_BASE_URL+data.logo:null;
        this.phoneNumber=data?.phone_number;
        this.email=data?.email;
        this.about=data?.about;
        this.isUserVisible=data?.is_user_visible;
        this.isRestricted=data?.is_restricted;
    }
}