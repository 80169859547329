import BaseCRUDPageLayout from '@/core/base/layouts/BaseCRUDPageLayout.vue';

<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> الملف الشخصي</template>
    <v-container class="pt-6">
      <v-row>
        <v-col>
          <div>الاسم : {{ username }}</div>
          <div>البريد الالكتروني : {{ email }}</div>
          <ChangePasswordForm />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import ChangePasswordForm from "../components/ChangePasswordForm.vue";
import { useAuthStore } from "../store";

export default {
    mounted() {
        this.fetchProfileInfo()
            .then()
            .catch((err) => console.error(err));
    },
    data() {
        return {
            links: [
                {
                    text: "الصفحة الرئيسية",
                    href: "/",
                    disabled: false,
                },
                {
                    text: "الملف الشخصي",
                    disabled: true,
                },
            ],
        };
    },
    methods: {
        ...mapActions(useAuthStore, ["fetchProfileInfo"]),
    },
    computed: {
        ...mapState(useAuthStore, ["username", "email"]),
    },
    components: { ChangePasswordForm }
};
</script>
