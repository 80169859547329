export const getEthicalAdvicesAPI = "/ethical-advice";
export const createEthicalAdviceAPI = "/ethical-advice";
export const getEthicalAdvicesSchedulingCalenderAPI = "/ethical-advice/schedule";

export const sendEthicalAdvicesSchedulingCalenderAPI =
  "/ethical-advice/schedule";

export function editEthicalAdviceAPI(id) {
  return "/ethical-advice/" + id;
}
export function deleteEthicalAdviceAPI(id) {
  return "/ethical-advice/" + id;
}
