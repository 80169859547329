<template>
  <BaseTableItemAction
    v-can:[deletePermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-delete"
    actionName="حذف"
    @click="openDeleteDialog()"
  >
    <DeleteCATQuestionDialog
      :showCondition="showDeleteDialog"
      :item="item"
      @closed="closeDeleteDialog"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_CAREER_TEST_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";
import DeleteCATQuestionDialog from "../dialogs/DeleteCATQuestionDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      deletePermission: WRITE_CAREER_TEST_QUESTIONS_PERMISSION,
      showDeleteDialog: false,
    };
  },
  methods: {
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
  },
  components: { DeleteCATQuestionDialog },
};
</script>

<style></style>
