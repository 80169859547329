import { READ_NEWS_PERMISSION } from "@/core/statics/Permissions";
import { WRITE_NEWS_PERMISSION } from "@/core/statics/Permissions";

import NewsPage from "../pages/NewsPage.vue";
import EditNewsPage from "../pages/EditNewsPage.vue";
import UserLayout from "@/core/layouts/UserLayout.vue";
import CreateNewsPage from "../pages/CreateNewsPage.vue";
import NewsDetailsPage from "../pages/NewsDetailsPage.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";

export default {
  path: "/news",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "news",
      component: NewsPage,
      meta: {
        title: "الأخبار",
        requiredPermission: READ_NEWS_PERMISSION,
      },
    },
    {
      component: BaseFormPageWrapper,
      path: "/news/create",
      children: [
        {
          path: "",
          name: "create_news",
          component: CreateNewsPage,
          meta: {
            title: "إضافة خبر",
            requiredPermission: WRITE_NEWS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/news/:id",
      name: "news_details",
      component: NewsDetailsPage,
      meta: {
        title: "تفاصيل خبر", //TODO: add news title to page title?
        requiredPermission: READ_NEWS_PERMISSION,
      },
    },
    {
      path: "/news/edit/:id",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_news",
          component: EditNewsPage,
          meta: {
            title: "تعديل خبر", //TODO: add news title to page title?
            requiredPermission: WRITE_NEWS_PERMISSION,
          },
        },
      ],
    },
  ],
};
