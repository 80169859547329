<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تفاصيل الخبر رقم {{ news.id }} </template>
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <DetailsPage :obj="news" :isNews="true" />
        </v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditNewsButton
            :item="news"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteNewsButton
            :item="news"
            :isIcon="false"
            :goBackWhenItsFinished="true"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useNewsStore } from "../store/NewsStore";

import DetailsPage from "@/core/components/news_and_projects/DetailsPage.vue";
import DeleteNewsButton from "../components/action_buttons/DeleteNewsButton.vue";
import EditNewsButton from "../components/action_buttons/EditNewsButton.vue";

export default {
  components: { DetailsPage, DeleteNewsButton, EditNewsButton },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "الأخبار",
          href: "/news",
          disabled: false,
        },
        {
          text: "الخبر رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(useNewsStore, ["news"]),
  },
  methods: {
    ...mapActions(useNewsStore, ["getNewsDetails"]),
  },
  mounted() {
    this.getNewsDetails(this.$route.params.id)
      .then(() => (this.fetchingData = false))
      .catch((err) => console.error(err));
  },
};
</script>

<style>
.carousel {
  border: 2px solid var(--v-primary-base);
  max-width: 600px;
}
.date {
  color: grey;
}
.media {
  width: 100%;
  height: 100%;
}
</style>
