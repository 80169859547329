<template>
  <v-switch
    v-can:[togglePermission]
    :input-value="item.isActivated"
    inset
    readonly
    @click="toggleActivation()"
  ></v-switch>
</template>

<script>
import { WRITE_TP_APPLICATION_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";
import { mapActions } from "pinia";
import { useTrainingProgramQuestionStore } from "../../store/TrainingProgramApplicationQuestionStore";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      togglePermission: WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useTrainingProgramQuestionStore, ["toggleQuestion"]),
    toggleActivation() {
      this.toggleQuestion(this.item.id)
        .then()
        .catch((err) => console.error(err));
    },
  },
};
</script>
