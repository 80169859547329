<template>
  <div v-can:[createPermission]>
    <v-btn color="accent" to="/news/create">
      <v-icon>mdi-plus</v-icon> إضافة خبر
    </v-btn>
  </div>
</template>

<script>
import { WRITE_NEWS_PERMISSION } from "@/core/statics/Permissions";

export default {
  data() {
    return {
      createPermission: WRITE_NEWS_PERMISSION,
    };
  },
};
</script>
