<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">{{ title }}</p>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row justify="center">
          <v-col align="center" cols="9">
            <p>{{ confirmationMessage }}</p>
            <slot name="alert"></slot>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-row justify="space-around">
        <v-btn @click="closeDialog()" class="lightNeutral rounded-pill px-10"
          >لا</v-btn
        >
        <slot name="confirmationButton"></slot>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  props: {
    showCondition: Boolean,
    title: String,
    confirmationMessage: String,
    item: Object,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
  },
};
</script>
