import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import PlatformFeedback from "./PlatformFeedback";

export default class PaginatedPlatformFeedbackCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(feedback => {
      this.collection.push(new PlatformFeedback(feedback));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(feedback => {
      this.collection.push(new PlatformFeedback(feedback));
    });
  }
}
