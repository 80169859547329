<template>
    <BaseCountCard :value="publishersStats.publishersTotalCount" unit="ناشر فرصة عمل ضمن المنصة" />
</template>
<script>
import { useStatisticsStore } from '@/modules/home/store/StatisticsStore';
import { mapState } from 'pinia';
import BaseCountCard from '../../base_statistics_cards/BaseCountCard.vue';

export default{
    components: { BaseCountCard },
    computed:{
        ...mapState(useStatisticsStore,["publishersStats"]),
    }
}
</script>