import UserLayout from "@/core/layouts/UserLayout.vue";
import { READ_TP_SUGGESTIONS_PERMISSION } from "@/core/statics/Permissions";
import TrainingProgramSuggestionsPage from "../pages/TrainingProgramSuggestionsPage.vue";

export default {
  path: "/training-program-suggestions",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "training_program_suggestions",
      component: TrainingProgramSuggestionsPage,
      meta: {
        title:'اقتراحات المنح التدريبية',
        requiredPermission: READ_TP_SUGGESTIONS_PERMISSION,
      },
    },
    
  ],
};
