export const getProjectsAPI = "/projects";
export const createProjectAPI = "/projects";

export function getProjectAPI(id) {
  return "/projects/" + id;
}
export function editProjectAPI(id) {
  return "/projects/" + id;
}
export function deleteProjectAPI(id) {
  return "/projects/" + id;
}

export function uploadVideoAPI(id) {
  return "/projects/upload-video/" + id;
}
