import Notification from "../Notification";

export default class NewTrainingProgramApplicationNotification extends Notification{
    constructor(data){
        super(data);
        this.tpApplicationId=data.data.training_program_application_id;
        this.tpTitle=data.data.training_program_title;
    }
    getTitle(){
        return 'طلب جديد على منحة برنامج تدريبي'
    }
    getBody(){
        return `لقد تم استلام طلب جديد لمنح البرامج التدريبية في البرنامج التدريبي"${this.tpTitle}"، رقم الطلب : #${this.tpApplicationId} `
    }
    getActionLink(){
        return `/training-program-applications/${this.tpApplicationId}`
    }
}