<template>
  <div>
    <v-btn
      v-can:[showFeedbackPermission]
      color="primary"
      to="/job-opportunities/feedback"
    >
      <v-icon class="ml-1">mdi-comment-quote-outline</v-icon>
      عرض الملاحظات المرسلة 
    </v-btn>
  </div>
</template>

<script>
import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";

export default {
  data() {
    return {
      showFeedbackPermission: READ_JOBS_PERMISSION,
    };
  },
};
</script>
