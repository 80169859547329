import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgramSuggestionsService from "../services/TrainingProgramSuggestionsService";
import TrainingProgramSuggestionKeyTransformations from "../models/TrainingProgramSuggestionKeyTransformations";
import PaginatedTrainingProgramSuggestionCollection from "../models/PaginatedTrainingProgramSuggestionCollection";

export const useTrainingProgramSuggestionsStore = defineStore(
  "trainingProgramSuggestionsStore",
  {
    state: () => ({
      paginatedSuggestions: new PaginatedTrainingProgramSuggestionCollection(),
      suggestionTableOptions: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      searchKey: null,
      suggestingStatus: true,
    }),
    getters: {
      getSearchPageParameters() {
        //prepare sort object in case sort applied (and transform sort cols name)
        var sortObj =
          this.suggestionTableOptions.sortBy.length != 0
            ? {
                sort_by:
                  TrainingProgramSuggestionKeyTransformations[
                    this.suggestionTableOptions.sortBy[0]
                  ],
                sort_desc: this.suggestionTableOptions.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.suggestionTableOptions.page,
          ...sortObj,
          items_per_page: this.suggestionTableOptions.itemsPerPage,
          search_key: this.searchKey,
        });
      },
    },
    actions: {
      setTableOptionsNFetch(options) {
        this.suggestionTableOptions = options;
        this.fetchSuggestions();
      },
      setSearchNFetch(searchKey) {
        this.searchKey = searchKey;
        this.resetPaginatedSuggestions();
        this.fetchSuggestions();
      },
      resetPaginatedSuggestions() {
        this.paginatedSuggestions =
          new PaginatedTrainingProgramSuggestionCollection();
      },
      fetchSuggestingStatus() {
        return BaseStore.promiseHandler(
          () => TrainingProgramSuggestionsService.getSuggestingStatus(),
          (data) => {
            this.suggestingStatus = data.data?.is_enabled;
          }
        );
      },
      toggleSuggestingStatus() {
        return BaseStore.promiseHandler(
          () => TrainingProgramSuggestionsService.toggleSuggestingStatus(),
          () => {
            this.suggestingStatus = !this.suggestingStatus;
          }
        );
      },
      fetchSuggestions() {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramSuggestionsService.getTrainingProgramSuggestionsAPI(
              this.getSearchPageParameters
            ),
          (data) => {
            this.paginatedSuggestions =
              new PaginatedTrainingProgramSuggestionCollection(data);
          }
        );
      },
    },
  }
);
