<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>طلبات المنح التدريبية </template>
      <template v-slot:tableActions>
        <FormatExportKeysButton class="mr-5" />
        <ExportApplicationsButton class="mr-5" />
        <FilterApplicationsButton class="mr-5" />
      </template>
      <template v-slot:dataTable>
        <TrainingProgramApplicationsTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import TrainingProgramApplicationsTable from "../components/TrainingProgramApplicationsTable.vue";
  import FilterApplicationsButton from "../components/action_buttons/FilterApplicationsButton.vue";
  import ExportApplicationsButton from "../components/action_buttons/ExportApplicationsButton.vue";
  import FormatExportKeysButton from "../components/action_buttons/FormatExportKeysButton.vue";

  export default {
    data() {
      return {
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "طلبات المنح التدريبية",
            disabled: true,
          },
        ],
      };
    },
    components: { TrainingProgramApplicationsTable, FilterApplicationsButton, ExportApplicationsButton,FormatExportKeysButton },
  };
  </script>
  