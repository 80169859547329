import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import TrainingProgram from "./TrainingProgram";

export default class PaginatedTrainingProgramCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((tp) => {
      this.collection.push(new TrainingProgram(tp));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((tp) => {
      this.collection.push(new TrainingProgram(tp));
    });
  }
}
