import { defineStore } from "pinia";

import HomeService from "../services/HomeService";
import BaseStore from "@/core/classes/base/BaseStore";
import JobsStatistics from "../models/JobsStatistics";
import UserStatistics from "../models/UsersStatistics";
import PublishersStatistics from "../models/PublishersStatistics";
import TPApplicationsStatistics from "../models/TPApplicationsStatistics";
import { StatisticsType } from "../components/statics/StatisticsTypes";

export const useStatisticsStore = defineStore("statisticsStore", {
  state: () => ({
    //tp stats
    TPAppsStats: new TPApplicationsStatistics(),
    //jobs stats
    jobsStats: new JobsStatistics(),
    //users stats
    usersStats: new UserStatistics(),
    //publishers stats
    publishersStats: new PublishersStatistics(),
  }),
  getters: {
    //stats parameters getters
    getTPsStatsParams() {
      return new Object({
        TP_apps_count_duration: this.TPAppsStats.TPAppsCountDuration,
        TP_apps_count_per_TP_duration:
          this.TPAppsStats.TPAppsCountPerTPDuration,
      });
    },
    getJobStatsParams() {
      return new Object({
        published_jobs_count_duration:
          this.jobsStats.publishedJobsCountDuration,
        job_applications_count_duration:
          this.jobsStats.jobApplicationsCountDuration,
        jobs_feedback_count_duration: this.jobsStats.jobsFeedbackCountDuration,
        top_8_jobs_count_per_field_duration:
          this.jobsStats.top8JobsCountPerFieldDuration,
        jobs_closing_reasons_count_per_reason_duration:
          this.jobsStats.jobsClosingReasonsCountPerReasonDuration,
        applicants_rate_count_per_rate_duration:
          this.jobsStats.applicantsRateCountPerRateDuration,
        jobs_reopen_reasons_count_per_reason_duration:
          this.jobsStats.jobsReopenReasonsCountPerReasonDuration,
      });
    },
    getUsersStatsParams() {
      return new Object({
        new_users_count_duration: this.usersStats.newUsersCountDuration,
        career_aptitude_test_results_count_duration:
          this.usersStats.careerAptitudeTestResultsCountDuration,
      });
    },
    getPublishersStatsParams() {
      return new Object({
        new_publishers_count_duration:
          this.publishersStats.newPublishersCountDuration,
      });
    },
  },
  actions: {
    //extract labels and data from statistical objects actions
    getLabelsArray(statsObj) {
      var labels = [];
      Object.keys(statsObj).forEach((label) => {
        labels.push(label);
      });
      return labels;
    },
    getDataArray(statsObj) {
      var dataValues = [];
      Object.keys(statsObj).forEach((label) => {
        dataValues.push(statsObj[label]);
      });
      return dataValues;
    },

    /**
     * Stores the provided data in local storage using the given key.
     * @param {string} key - The key under which to store the data.
     * @param {any} data - The data to be stored.
     */
    storeStatisticsInLocalStorage(statisticsType, statistics) {
      localStorage.setItem(statisticsType, JSON.stringify(statistics));
    },

    /**
     * Returns the appropriate fetch function based on the provided statistics key.
     * @param {string} StatisticsType - The key used to identify the desired fetch function.
     * @returns {Function} The matched fetch function if found
     */
    getStatisticsFetcher(statisticsType) {
      // Implement logic to map statistics keys to fetch functions
      // Returns the fetch function
      switch (statisticsType) {
        case StatisticsType.TPsStatistics:
          return this.fetchTPsStatistics;
        case StatisticsType.UsersStatistics:
          return this.fetchUsersStatistics;
        case StatisticsType.JobsStatistics:
          return this.fetchJobsStatistics;
        case StatisticsType.PublishersStatistics:
          return this.fetchPublishersStatistics;
      }
    },

    /**
     * Checks if the local storage is empty for the given statistics key.
     * @param {string} statisticsType - The key to check in local storage.
     * @returns {boolean} True if the local storage is empty, false if not.
     */
    shouldFetch(statisticsType) {
      return localStorage.getItem(statisticsType) === null;
    },

    /**
     * Retrieves statistics from local storage based on the provided statistics type,
     * creates an object for the matched statistics type, and assigns it to the
     * appropriate item in the state.
     * @param {string} statisticsType - The type of statistics to fetch from local storage.
     */
    // fetchLocalStatistics(statisticsType) {
    //   return new Promise((resolve) => {
    //     try {
    //       const statistics = JSON.parse(localStorage.getItem(statisticsType));
    //       switch (statisticsType) {
    //         case StatisticsType.TPsStatistics:
    //           this.TPAppsStats = new TPApplicationsStatistics(statistics);
    //           break;
    //         case StatisticsType.UsersStatistics:
    //           this.usersStats = new UserStatistics(statistics);
    //           break;
    //         case StatisticsType.JobsStatistics:
    //           this.jobsStats = new JobsStatistics(statistics);
    //           break;
    //         case StatisticsType.PublishersStatistics:
    //           this.publishersStats = new PublishersStatistics(statistics);
    //           break;
    //       }
    //       resolve();
    //     } catch (error) {
    //       return this.getStatisticsFetcher(statisticsType)();
    //     }
    //   });
    // },

    fetchStatistics(statisticsType) {
      if (this.shouldFetch(statisticsType)) {
        const statisticsFetcherFunction =
          this.getStatisticsFetcher(statisticsType);
        // execute the function then return the result
        return statisticsFetcherFunction();
      }
    },

    //fetch stats actions
    fetchTPsStatistics() {
      return BaseStore.promiseHandler(
        () => HomeService.getTPStatistics(this.getTPsStatsParams),
        (data) => {
          this.TPAppsStats = new TPApplicationsStatistics({
            ...data.data,
            ...this.getTPsStatsParams,
          });
          this.storeStatisticsInLocalStorage(StatisticsType.TPsStatistics, {
            ...data.data,
            ...this.getTPsStatsParams,
          });
        }
      );
    },
    fetchJobsStatistics() {
      return BaseStore.promiseHandler(
        () => HomeService.getJobsStatistics(this.getJobStatsParams),
        (data) => {
          this.jobsStats = new JobsStatistics({
            ...data.data,
            ...this.getJobStatsParams,
          });
          this.storeStatisticsInLocalStorage(StatisticsType.JobsStatistics, {
            ...data.data,
            ...this.getJobStatsParams,
          });
        }
      );
    },
    fetchUsersStatistics() {
      return BaseStore.promiseHandler(
        () => HomeService.getUsersStatistics(this.getUsersStatsParams),
        (data) => {
          this.usersStats = new UserStatistics({
            ...data.data,
            ...this.getUsersStatsParams,
          });
          this.storeStatisticsInLocalStorage(StatisticsType.UsersStatistics, {
            ...data.data,
            ...this.getUsersStatsParams,
          });
        }
      );
    },
    fetchPublishersStatistics() {
      return BaseStore.promiseHandler(
        () =>
          HomeService.getPublishersStatistics(this.getPublishersStatsParams),
        (data) => {
          this.publishersStats = new PublishersStatistics({
            ...data.data,
            ...this.getPublishersStatsParams,
          });
          this.storeStatisticsInLocalStorage(
            StatisticsType.PublishersStatistics,
            {
              ...data.data,
              ...this.getPublishersStatsParams,
            }
          );
        }
      );
    },
  },
});
