import keysTransformations from "./TrainingProgramKeyTransformations";

export default class TrainingProgram {
  id;
  name;
  isEnabled;
  programConditions;
  introductoryVideoPath;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.title;
    this.isEnabled = data?.is_enabled ?? 1;
    this.programConditions = data?.program_conditions;
    this.introductoryVideoPath =
      process.env.VUE_APP_BASE_URL + data?.introductory_video_path;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
}
