<template>
    <BaseDataTable
      :headers="headers"
      :paginatedItems="paginatedTrainerResumes"
      @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
      @optionsChanged="(options) => setTableOptionsNFetch(options)"
    >
     
      <template v-slot:[`item.resume`]="{ item }">
        <a :href="item.resume" target="_blank" download>
          <v-icon>mdi-file</v-icon>
        </a>
      </template>

      <template v-slot:[`item.trainer.name`]="{ item }">
        <RouterLink v-if="hasPermission(showTrainersPermission)" :to="'/users/'+item.trainer.id">
          {{ item.trainer.name }}
        </RouterLink>
        <label v-else>  {{ item.trainer.name }}</label>
      </template>
    </BaseDataTable>
  </template>
  
  <script>
  import { mapActions, mapState, mapWritableState } from "pinia";
  import {useTrainerResumesStore  } from "../store/TrainerResumesStore";
  import { RouterLink } from "vue-router";
  import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
  import { READ_USERS_PERMISSION } from "@/core/statics/Permissions";
  
  export default {
    mixins:[PermissionManagerMixin],
    components: {
      RouterLink
    },
    computed: {
      ...mapState(useTrainerResumesStore, ["paginatedTrainerResumes"]),
      ...mapWritableState(useTrainerResumesStore,["searchKey"]),
    },
    data() {
      return {
        showTrainersPermission:READ_USERS_PERMISSION,
        headers: [
          { text: "الرقم", align: "start", value: "id" },
          { text: "الاسم", value: "trainer.name" },
          { text: "تاريخ الإرسال", value: "createdAt" },
          { text: "البريد الالكتروني", value: "trainer.email" },
          { text: "رقم الهاتف", value: "trainer.phoneNumber" },
          { text: "مجال التدريب", value: "trainingField" },
          { text: "السيرة الذاتية",  sortable: false,value: "resume" },
        ],
      };
    },
    methods: {
      ...mapActions(useTrainerResumesStore, [
        "fetchTrainerResumes",
        "setSearchNFetch",
        "setTableOptionsNFetch"
      ]),
    },
    mounted() {
      this.fetchTrainerResumes().then().catch((err)=>console.error(err));
    },
    destroyed(){
      this.searchKey=null;
    },
  };
  </script>