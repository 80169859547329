<template>
    <div>
      <v-btn
        v-can:[sortPermission]
        :to="'/training-program-application-questions/sort'"
        color="primary"
      >
        <v-icon class="ml-3">mdi-format-list-numbered</v-icon>
       ترتيب ظهور الأسئلة
      </v-btn>
    </div>
  </template>
  <script>
  import { WRITE_TP_APPLICATION_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";
  
  export default {
    data() {
      return {
        sortPermission: WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
      };
    },
    methods: {},
  };
  </script>
  