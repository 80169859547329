<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <BaseTextField
            :rules="[requiredRule]"
            v-model="admin.name"
            label="اسم المشرف"
            placeholder="اسم المشرف"
            persistent-placeholder
          ></BaseTextField>

          <BaseTextField
            :rules="[requiredRule, emailRule]"
            v-model="admin.email"
            label="البريد الالكتروني"
            placeholder="البريد الالكتروني الخاص بالمشرف"
            persistent-placeholder
          ></BaseTextField>

          <BasePasswordField
            :rules="isEdit ? [passwordRule] : [requiredRule, passwordRule]"
            v-model="admin.password"
            label="كلمة السر"
            placeholder="كلمة السر الخاصة بالمشرف"
            persistent-placeholder
          ></BasePasswordField>

          <BaseSelect
            :rules="[requiredRule]"
            :items="permissions"
            v-model="admin.permissions"
            chips
            clearable
            multiple
            label="الصلاحيات"
            placeholder="الصلاحيات الخاصة بالمشرف"
            hint="إن إعطاء صلاحية الكتابة لخاصية ما يؤدي إلى إعطاء صلاحية القراءة لنفس الخاصية بشكل تلقائي للمشرف"
            persistent-placeholder
            persistent-hint
          >
            <template v-slot:item="{ item }">
              {{ permissionTranslationMapper[item] }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip> {{ permissionTranslationMapper[item] }}</v-chip>
            </template>
          </BaseSelect>

          <v-row align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="emitSubmission()"
            >
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { EventBus } from "@/main";
import { PERMISSIONS } from "@/core/statics/Permissions";
import { permissionTranslationMapper } from "@/core//statics/PermissionsTranslationMapper";

export default {
  props: {
    adminObject: Object,
    formActionName: String,
    // This prop is important for the password field rules. if [isEdit] the password field should not be required
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      admin: this.adminObject,
      permissions: PERMISSIONS,
      permissionTranslationMapper: permissionTranslationMapper,
    };
  },
  watch: {
    adminObject: {
      immediate: true,
      handler() {
        this.admin = this.adminObject;
      },
    },
    admin: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  methods: {
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("adminSubmitted", this.admin);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>
