<template>
    <v-select
          v-model="selectedFAQCategory"
          item-value="id"
          class="selection-width"
          :items="tags"
          menu-props="auto"
          label="تصفية حسب التصنيف "
          single-line
          hide-details
          prepend-inner-icon="mdi-filter"
          solo
          dense
          dark
          clearable
          background-color="accent"
          v-on="$listeners"
          v-bind="$attrs"
          @change="setFilterState()"
        >
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:selection="{ item }">
         {{ item.name }}
        </template>
        </v-select>
</template>

<script>
import { mapActions,mapState, mapWritableState } from 'pinia';
import {useFAQsStore} from '../store/FAQsStore';

export default{
    mounted(){
        this.fetchFAQsTags().then().catch((err)=>console.error(err))
    },
    computed:{
        ...mapState(useFAQsStore,["tags"]),
        ...mapWritableState(useFAQsStore,["selectedFAQCategory"])
    },
    methods:{
        ...mapActions(useFAQsStore,['setFilter','fetchFAQsTags']),
        setFilterState(){
            this.setFilter(this.selectedFAQCategory);
        }
    }
}
</script>

<style scoped>
.selection-width{
    max-width: 200px;
}
</style>