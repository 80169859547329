<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <QuestionDetails :question="question"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditTPApplicationQuestionButton
            :item="question"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import EditTPApplicationQuestionButton from "../components/action_buttons/EditTPApplicationQuestionButton.vue";
import QuestionDetails from "../components/QuestionDetails.vue";
import { useTrainingProgramQuestionStore } from "../store/TrainingProgramApplicationQuestionStore";

export default {
  mounted() {
    var questionId = this.$route.params.id;
    this.fetchQuestion(questionId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useTrainingProgramQuestionStore, ["question"]),
  },
  methods: {
    ...mapActions(useTrainingProgramQuestionStore, ["fetchQuestion"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "أسئلة التقدم على منحة تدريبية",
          href: "/training-program-application-questions",
          disabled: false,
        },
        {
          text: "تفاصيل السؤال رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { QuestionDetails, EditTPApplicationQuestionButton },
};
</script>
