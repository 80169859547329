import { defineStore } from "pinia";
import { useCareerAptitudeTestQuestionsStore } from "./CareerAptitudeTestQuestionsStore";

import BaseStore from "@/core/classes/base/BaseStore";
import CareerAptitudeTestQuestionsService from "../services/CareerAptitudeTestQuestionsService";

export const useCareerAptitudeTestQuestionStore = defineStore(
  "careerAptitudeTestQuestionStore",
  {
    state: () => ({}),
    getters: {
      allQuestions() {
        const careerAptitudeTestQuestionsStore =
          useCareerAptitudeTestQuestionsStore();
        return careerAptitudeTestQuestionsStore.paginatedQuestions.collection;
      },
    },
    actions: {
      deleteQuestion(id) {
        return BaseStore.promiseHandler(
          () =>
            CareerAptitudeTestQuestionsService.deleteCareerAptitudeQuestion(id),
          () => {
            this.allQuestions.splice(
              this.allQuestions.findIndex((q) => q.id === id),
              1
            );
          }
        );
      },
      createQuestion(data) {
        return BaseStore.promiseHandler(() =>
          CareerAptitudeTestQuestionsService.createCareerAptitudeQuestion(data)
        );
      },
      editQuestion(id, data) {
        return BaseStore.promiseHandler(
          () =>
            CareerAptitudeTestQuestionsService.editCareerAptitudeQuestion(
              id,
              data
            ),
          () => {
            var index = this.allQuestions.findIndex((q) => q.id === id);
            this.allQuestions[index] = data;
          }
        );
      },
    },
  }
);
