<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedApplicants"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowApplicantDetailsButton :item="item" />
      <PassApplicantButton :item="item" />
      <RejectApplicantButton :item="item" />
    </template>
    <template v-slot:[`item.isOrganizationGraduate`]="{ item }">
      <v-icon v-if="item.isOrganizationGraduate == 1">mdi-check</v-icon>
      <v-icon v-else>mdi-close</v-icon>
    </template>
    <template v-slot:[`item.jobResume`]="{ item }">
      <a :href="item.jobResume" target="_blank" download>
        <v-icon>mdi-file</v-icon>
      </a>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      {{ translateStatus(item.status) }}
    </template>
    <template v-slot:[`item.applicant.name`]="{ item }">
      <RouterLink v-if="hasPermission(showApplicantPermission)" :to="'/users/'+item.applicant.id">
        {{ item.applicant.name }}
      </RouterLink>
      <label v-else>  {{ item.applicant.name }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import JobApplicant from "../../models/JobApplication";
import { useJobApplicantsStore } from "../../store/JobApplicantsStore";
import PassApplicantButton from "../action_buttons/applicant_actions/PassApplicantButton.vue";
import RejectApplicantButton from "../action_buttons/applicant_actions/RejectApplicantButton.vue";
import ShowApplicantDetailsButton from "../action_buttons/applicant_actions/ShowApplicantDetailsButton.vue";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_USERS_PERMISSION } from "@/core/statics/Permissions";

export default {
  mixins:[PermissionManagerMixin],
  components: {
    PassApplicantButton,
    RejectApplicantButton,
    ShowApplicantDetailsButton,
    RouterLink
  },
  computed: {
    ...mapState(useJobApplicantsStore, ["paginatedApplicants"]),
    ...mapWritableState(useJobApplicantsStore,["searchKey"]),
  },
  data() {
    return {
      showApplicantPermission:READ_USERS_PERMISSION,
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "الاسم", value: "applicant.name" },
        { text: "تاريخ التقدم", value: "createdAt" },
        { text: "البريد الالكتروني", value: "applicant.email" },
        { text: "رقم الهاتف", value: "applicant.phoneNumber" },
        { text: "حالة الطلب", value: "status" },
        { text: "خريج الجمعية", value: "isOrganizationGraduate" },
        { text: "السيرة الذاتية",  sortable: false,value: "jobResume" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useJobApplicantsStore, [
      "fetchJobApplicants",
      "setSearchNFetch",
      "setTableOptionsNFetch",
      "setJobId",
    ]),
    translateStatus(status){
        return JobApplicant.trans(status);
    }
  },
  mounted() {
    this.setJobId(this.$route.params.id);
    this.fetchJobApplicants();
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
