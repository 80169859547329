<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> إدارة مجالات فرص العمل </template>
    <template v-slot:tableActions>
      <CreateJobFieldButton />
    </template>
    <template v-slot:dataTable>
      <JobFieldsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import JobFieldsTable from "../components/JobFieldsTable.vue";
import CreateJobFieldButton from "../components/action_buttons/CreateJobFieldButton.vue";


export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة مجالات فرص العمل",
          disabled: true,
        },
      ],
    };
  },
  components: { CreateJobFieldButton, JobFieldsTable },
};
</script>
