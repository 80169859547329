export default class Notification {
  id;
  readAt;
  type;
  createdAt;

  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.readAt = data.read_at;
    this.createdAt= data.created_at;
    
  }
  getFormattedCreatedAt(){
    const dateString = this.createdAt;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `يوم ${year}-${month}-${day} الساعة ${hour}:${minutes}`;
  }

  getTitle() {
    return "عنوان افتراضي";
  }
  getBody() {
    return "نص الإشعار";
  }
  getActionLink() {
    return "/";
  }
}
