import { StatisticsType } from "../components/statics/StatisticsTypes";

export default class JobsStatistics {
  jobsCountPerDuration;
  jobsTotalCount;
  jobsApplicationsTotalCount;
  jobsApplicationsCountPerDuration;
  jobsFeedbackCountPerDuration;
  top8JobsCountPerFieldPerDuration;
  applicantsRateCountPerRatePerDuration;
  jobsClosingReasonsCountPerReasonPerDuration;
  jobsRenewalReasonsCountPerReasonPerDuration;
  //durations of each stat
  publishedJobsCountDuration;
  jobApplicationsCountDuration;
  jobsFeedbackCountDuration;
  top8JobsCountPerFieldDuration;
  jobsClosingReasonsCountPerReasonDuration;
  applicantsRateCountPerRateDuration;
  jobsReopenReasonsCountPerReasonDuration;
  constructor(data) {
    if (data) {
      this.jobsCountPerDuration = data?.published_jobs_count_per_duration;
      this.jobsTotalCount = data?.total_published_jobs_count;
      this.jobsApplicationsTotalCount = data?.total_job_applications_count;
      this.jobsApplicationsCountPerDuration =
        data?.job_applications_count_per_duration;
      this.jobsFeedbackCountPerDuration =
        data?.jobs_feedback_count_per_duration;
      this.top8JobsCountPerFieldPerDuration =
        data?.top_8_jobs_count_per_field_per_duration;
      this.applicantsRateCountPerRatePerDuration =
        data?.applicants_rate_count_per_rate_per_duration;
      this.jobsClosingReasonsCountPerReasonPerDuration =
        data?.jobs_closing_reasons_count_per_reason_per_duration;
      this.jobsRenewalReasonsCountPerReasonPerDuration =
        data?.jobs_reopen_reasons_count_per_reason_per_duration;
      //setting durations
      this.publishedJobsCountDuration = data?.published_jobs_count_duration;
      this.jobApplicationsCountDuration = data?.job_applications_count_duration;
      this.jobsFeedbackCountDuration = data?.jobs_feedback_count_duration;
      this.top8JobsCountPerFieldDuration =
        data?.top_8_jobs_count_per_field_duration;
      this.jobsClosingReasonsCountPerReasonDuration =
        data?.jobs_closing_reasons_count_per_reason_duration;
      this.applicantsRateCountPerRateDuration =
        data?.applicants_rate_count_per_rate_duration;
      this.jobsReopenReasonsCountPerReasonDuration =
        data?.jobs_reopen_reasons_count_per_reason_duration;
    } else {
      const localData = JSON.parse(
        localStorage.getItem(StatisticsType.JobsStatistics)
      );
      this.jobsCountPerDuration =
        localData?.published_jobs_count_per_duration ?? {};
      this.jobsTotalCount = localData?.total_published_jobs_count ?? null;
      this.jobsApplicationsTotalCount =
        localData?.total_job_applications_count ?? null;
      this.jobsApplicationsCountPerDuration =
        localData?.job_applications_count_per_duration ?? {};
      this.jobsFeedbackCountPerDuration =
        localData?.jobs_feedback_count_per_duration ?? {};
      this.top8JobsCountPerFieldPerDuration =
        localData?.top_8_jobs_count_per_field_per_duration ?? {};
      this.applicantsRateCountPerRatePerDuration =
        localData?.applicants_rate_count_per_rate_per_duration ?? {};
      this.jobsClosingReasonsCountPerReasonPerDuration =
        localData?.jobs_closing_reasons_count_per_reason_per_duration ?? {};
      this.jobsRenewalReasonsCountPerReasonPerDuration =
        localData?.jobs_reopen_reasons_count_per_reason_per_duration ?? {};
      //setting durations
      this.publishedJobsCountDuration =
        localData?.published_jobs_count_duration ?? "6_months";
      this.jobApplicationsCountDuration =
        localData?.job_applications_count_duration ?? "6_months";
      this.jobsFeedbackCountDuration =
        localData?.jobs_feedback_count_duration ?? "6_months";
      this.top8JobsCountPerFieldDuration =
        localData?.top_8_jobs_count_per_field_duration ?? "6_months";
      this.jobsClosingReasonsCountPerReasonDuration =
        localData?.jobs_closing_reasons_count_per_reason_duration ?? "6_months";
      this.applicantsRateCountPerRateDuration =
        localData?.applicants_rate_count_per_rate_duration ?? "6_months";
      this.jobsReopenReasonsCountPerReasonDuration =
        localData?.jobs_reopen_reasons_count_per_reason_duration ?? "6_months";
    }
  }
}
