import keysTransformations from "./AdminKeyTransformations";

export default class Admin {
  id;
  name;
  email;
  password;
  permissions;
  createdAt;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.email = data?.email;
    this.password = data?.password;
    this.createdAt = data?.created_at;
    this.permissions = data?.permissions;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
}
