<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedJobs"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowJobDetailsButton :item="item" />
      <AcceptJobButton :item="item" />
      <RejectJobButton :item="item" />
      <ShowJobApplicantsButton :item="item" />
      <CloseJobButton :item="item" />
      <ShowStatusChangeRequestsButton :item="item" />
    </template>
    <template v-slot:[`item.publisherName`]="{ item }">
      <RouterLink v-if="hasPermission(showPublisherPermission)" :to="'/job-publishers/'+item.publisher.id">
        {{ item.publisherName }}
      </RouterLink>
      <label v-else>  {{ item.publisherName }}</label>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      {{ translateStatus(item.status) }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobOpportunitiesStore } from "../../store/JobOpportunitiesStore";
import JobOpportunity from "../../models/JobOpportunity";
import ShowJobDetailsButton from "../action_buttons/job_actions/ShowJobDetailsButton.vue";
import CloseJobButton from "../action_buttons/job_actions/CloseJobButton.vue";
import ShowJobApplicantsButton from "../action_buttons/job_actions/ShowJobApplicantsButton.vue";
import AcceptJobButton from "../action_buttons/job_actions/AcceptJobButton.vue";
import RejectJobButton from "../action_buttons/job_actions/RejectJobButton.vue";
import ShowStatusChangeRequestsButton from "../action_buttons/job_actions/ShowStatusChangeRequestsButton.vue";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";

export default {
  mixins:[PermissionManagerMixin],
  computed: {
    ...mapState(useJobOpportunitiesStore, ["paginatedJobs"]),
    ...mapWritableState(useJobOpportunitiesStore,["searchKey"]),
  },
  data() {
    return {
      showPublisherPermission:READ_JOB_PUBLISHERS_PERMISSION,
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "المسمى الوظيفي", value: "title" },
        { text: "اسم الجهة الناشرة", value: "publisherName" },
        { text: "تاريخ الإضافة", value: "createdAt" },
        { text: "الحالة", value: "status" },
        { text: "عدد المتقدمين", value: "applicantsCount" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchJobs();
  },
  methods: {
    ...mapActions(useJobOpportunitiesStore, [
      "fetchJobs",
      "setTableOptionsNFetch",
      "setSearchNFetch",
    ]),
    translateStatus(prop) {
      return JobOpportunity.trans(prop);
    },
  },
  components: {
    ShowJobDetailsButton,
    ShowJobApplicantsButton,
    CloseJobButton,
    AcceptJobButton,
    RejectJobButton,
    ShowStatusChangeRequestsButton,
    RouterLink
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
