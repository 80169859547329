<template>
    <BaseTableItemAction
      v-if="item.status == 'pending'"
      v-can:[rejectPermission]
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-close"
      actionName="رفض تمرير المتقدم"
      @click="openRejectApplicantDialog()"
    >
      <RejectApplicantConfirmationDialog
        :showCondition="showRejectDialog"
        :item="item"
        @closed="closeRejectApplicantDialog()"
      />
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";
  import RejectApplicantConfirmationDialog from "../../dialogs/applicant_dialogs/RejectApplicantConfirmationDialog.vue";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        rejectPermission: WRITE_JOBS_PERMISSION,
        showRejectDialog: false,
      };
    },
    methods: {
      openRejectApplicantDialog() {
        this.showRejectDialog = true;
      },
      closeRejectApplicantDialog() {
        this.showRejectDialog = false;
      },
    },
    components: { RejectApplicantConfirmationDialog },
  };
  </script>
  