import { defineStore } from "pinia";
import { useProjectsStore } from "./ProjectsStore";

import Project from "../models/Project";
import BaseStore from "@/core/classes/base/BaseStore";
import ProjectsService from "../services/ProjectsServices";

export const useProjectStore = defineStore("projectStore", {
  state: () => ({
    project: new Project(),
  }),
  getters: {
    allProjects() {
      const projectsStore = useProjectsStore();
      return projectsStore.paginatedProjects.collection;
    },
  },
  actions: {
    fetchProject(id) {
      return BaseStore.promiseHandler(
        () => ProjectsService.getProject(id),
        (data) => {
          this.project = new Project(data?.data);
        }
      );
    },
    deleteProject(id) {
      return BaseStore.promiseHandler(
        () => ProjectsService.deleteProject(id),
        () => {
          this.allProjects.splice(
            this.allProjects.findIndex((p) => p.id === id),
            1
          );
        }
      );
    },
    createProject(data, videos) {
      return BaseStore.promiseHandler(
        () => ProjectsService.createProject(data),
        (data) => {
          if (videos.length) {
            this.uploadProjectVideos(data?.data?.id, videos);
          }
        }
      );
    },
    editProject(id, data, videos) {
      return BaseStore.promiseHandler(
        () => ProjectsService.editProject(id, data),
        (data) => {
          if (videos.length) {
            this.uploadProjectVideos(data?.data?.id);
          }
        }
      );
    },
    uploadProjectVideos(id, videos) {
      ProjectsService.uploadProjectVideos(id, videos);
    },
  },
});
