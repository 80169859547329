<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="11">
          <v-row>
            <v-breadcrumbs :items="links">
              <template v-slot:divider>
                <v-icon>mdi-chevron-left</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="item.href"
                  class="text-subtitle-2 crumb-item"
                  :disabled="item.disabled"
                  exact
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
          <v-row>
            <h1 class="secondary--text">
              <slot name="tableName"></slot
              ><!--table title slot-->
            </h1>
            <v-spacer></v-spacer>
            <slot name="tableActions"></slot
            ><!--table actions slot (filter/create new etc)-->
          </v-row>
          <slot name="dataTable"> </slot
          ><!--data table slot-->
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "BaseCRUDPageLayout",
  props: {
    links: Array,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.f-s-lg {
  font-size: large;
}
</style>
