<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>
        المتقدمين على فرصة العمل رقم {{ id }}
      </template>
      <template v-slot:tableActions> <ApplicantsFilter class="mr-5" /> </template>
      <template v-slot:dataTable>
        <JobApplicantsTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import ApplicantsFilter from "../components/ApplicantsFilter.vue";
  import JobApplicantsTable from "../components/tables/JobApplicantsTable.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "إدارة فرص العمل",
            href: "/job-opportunities",
            disabled: false,
          },
          {
            text: "فرصة العمل رقم " + this.$route.params.id,
            href: "/job-opportunities/" + this.$route.params.id,
            disabled: false,
          },
          {
            text: "المتقدمين",
            disabled: true,
          },
        ],
      };
    },
    components: { JobApplicantsTable, ApplicantsFilter },
  };
  </script>
  
  <style></style>
  