<template>
  <v-list-item
    three-line
    dense
    @click="markAsReadAndRedirectToLink()"
    :class="[notification.readAt ? 'white' : 'lightNeutral', 'p-0']"
  >
    <v-list-item-content>
      <v-list-item-title class="primary--text">
        {{ notification.getTitle() }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ notification.getBody() }}
        <small class="secondary--text mx-1"
          >({{ notification.getFormattedCreatedAt() }})</small
        >
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions } from "pinia";
import { useNotificationsStore } from "../stores/NotificationsStore";
export default {
  props: {
    notification: Object,
  },
  methods: {
    ...mapActions(useNotificationsStore, ["markNotificationAsRead"]),
    markAsReadAndRedirectToLink() {
      //case it is not read , send a mark-as-read request
      if (!this.notification.readAt) {
        this.markNotificationAsRead(this.notification.id)
          .then()
          .catch((err) => console.error(err));
      }
      this.$router.push(this.notification.getActionLink());
    },
  },
};
</script>
