import keysTransformations from "./TrainingProgramQuestionKeyTransformations";
import Translations from "./statics/Translations";
export default class TrainingProgramQuestion {
  id;
  label;
  placeholder;
  note;
  stepNumber;
  isRequired;
  isActivated;
  answers=[];
  type;
  order;
  constructor(data){
    this.id=data?.id;
    this.label=data?.label;
    this.placeholder=data?.placeholder;
    this.note=data?.note;
    this.stepNumber=data?.step_number;
    this.isRequired=data?data.is_required:false;
    this.isActivated=data?.is_active;
    this.answers=data?.answers;
    this.type=data?.type;
    this.order=data?.order;
  }
  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
  
  static trans(prop) {
    return Translations[prop];
  }
}
