<template>
  <BaseConfirmationDialog
    :showCondition="showCondition"
    title="قبول فرصة عمل"
    confirmationMessage="هل أنت متأكد من قبول هذه الفرصة؟"
    @closed="$emit('closed')"
  >
    <!--alert area for blocked publishers-->
    <template v-slot:alert v-if="item.publisher.isRestricted">
      <v-alert outlined type="warning" dense border="left">
       تحذير: الجهة الناشرة محظورة ولن تظهر الفرصة للمستخدمين
      </v-alert>
    </template>
    <template v-slot:confirmationButton>
      <v-btn @click="confirmAction()" class="accent rounded-pill px-10">
        نعم
      </v-btn>
    </template>
  </BaseConfirmationDialog>
</template>
<script>
import { mapActions } from "pinia";
import { useJobOpportunityStore } from "../../../store/JobOpportunityStore";
import BaseConfirmationDialog from "../BaseConfirmationDialog.vue";
export default {
  props: {
    showCondition: Boolean,
    item: Object,
  },
  components: { BaseConfirmationDialog },
  methods: {
    ...mapActions(useJobOpportunityStore, ["acceptJob"]),
    confirmAction() {
      this.acceptJob(this.item.id)
        .then()
        .catch((err) => console.error(err));
    },
  },
};
</script>
