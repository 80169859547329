<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedRequests"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:[`item.requestType`]="{ item }">
      {{ translateStatus(item.requestType) }}
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import JobOpportunity from "../../models/JobOpportunity";
import { useJobStatusChangeRequestsStore } from "../../store/JobStatusChangeRequestsStore";

export default {
  computed: {
    ...mapState(useJobStatusChangeRequestsStore, ["paginatedRequests"]),
    ...mapWritableState(useJobStatusChangeRequestsStore,["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "نوع الطلب", value: "requestType" },
        { text: "السبب", value: "reason" },
        { text: "تاريخ الطلب", value: "createdAt" },
      ],
    };
  },
  mounted() {
    this.setJobId(this.$route.params.id);
    this.fetchRequests(); 
  },
  methods: {
    ...mapActions(useJobStatusChangeRequestsStore, [
      "fetchRequests",
      "setJobId",
      "setTableOptionsNFetch",
      "setSearchNFetch",
    ]),
    translateStatus(prop) {
      return JobOpportunity.trans(prop);
    },
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
