import UserLayout from "@/core/layouts/UserLayout.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import { READ_FAQS_PERMISSION ,WRITE_FAQS_PERMISSION} from "@/core/statics/Permissions";
import FAQsPage from "../pages/FAQsPage.vue";
import FAQCreatePage from "../pages/FAQCreatePage.vue";
import FAQEditPage from "../pages/FAQEditPage.vue";

export default {
  path: "/faqs",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "faqs",
      component: FAQsPage,
      meta: {
        title: "الأسئلة الشائعة",
        requiredPermission: READ_FAQS_PERMISSION,
      },
    },
    {
      path: "/faqs/create",
      component: BaseFormPageWrapper,
      children:[
        {
          path:"",
          component: FAQCreatePage,
          name: "create_faq",
          meta: {
            title: "إنشاء سؤال شائع",
            requiredPermission: WRITE_FAQS_PERMISSION,
          },
        }
      ]
     
    },
    {
      path: "/faqs/:id/edit",
      component: BaseFormPageWrapper,
      children:[
        {
          path:"",
          name: "edit_faq",
          component: FAQEditPage,
          meta: {
            title: "إنشاء سؤال شائع",
            requiredPermission: WRITE_FAQS_PERMISSION,
          },
        }
      ]
    },
  ],
};
