import NewApplicantNotification from "./notification_types/NewApplicantNotification";
import NewJobOpportunityNotification from "./notification_types/NewJobOpportunityNotification";
import NewJobReopenRequestNotification from "./notification_types/NewJobReopenRequestNotification";
import NewTrainingProgramApplicationNotification from "./notification_types/NewTrainingProgramApplicationNotification";
import NewApplicantFeedbackOnJobNotification from "./notification_types/NewApplicantFeedbackOnJobNotification";
import NewJobPublisherFeedbackOnApplicantNotification from "./notification_types/NewJobPublisherFeedbackOnApplicantNotification";
import Notification from "./Notification";

export default class NotificationFactory {
  static wrapNotification(data) {
    // based on notification type make specified notification instances
    if (data.type == "App\\Notifications\\NewJobApplicant")
      return new NewApplicantNotification(data);
    else if (data.type == "App\\Notifications\\NewJobOpportunity")
      return new NewJobOpportunityNotification(data);
    else if (data.type == "App\\Notifications\\NewJobReopenRequest")
      return new NewJobReopenRequestNotification(data);
    else if (data.type == "App\\Notifications\\NewTrainingProgramApplication")
      return new NewTrainingProgramApplicationNotification(data);
    else if (data.type == "App\\Notifications\\NewApplicantFeedbackOnJob")
      return new NewApplicantFeedbackOnJobNotification(data);
    else if (data.type == "App\\Notifications\\NewJobPublisherFeedbackOnApplicant")
      return new NewJobPublisherFeedbackOnApplicantNotification(data);
    else return new Notification(data);
  }
}
