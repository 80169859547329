import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import Project from "./Project";

export default class PaginatedProjectsCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((project) => {
      this.collection.push(new Project(project));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((project) => {
      this.collection.push(new Project(project));
    });
  }
}
