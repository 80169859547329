import BaseService from "@/core/classes/base/BaseService";
import {
    getTrainerResumesAPI
} from "./TrainerResumes.api";
import Client from "@/core/classes/client/Client";

export default class TrainerResumesService extends BaseService {
  static getTrainerResumes(params) {
    return Client.get(getTrainerResumesAPI,params).then((res) => Promise.resolve(res));
  }
}