<template>
  <div>
    <div class="my-5">
      <span class="primary--text">اسم المشرف: </span>{{ admin.name }}
    </div>
    <div class="my-5">
      <span class="primary--text">البريد الالكتروني: </span>{{ admin.email }}
    </div>
    <div class="my-5">
      <span class="primary--text">تاريخ الإضافة: </span>{{ admin.createdAt }}
    </div>
    <div class="my-5">
      <span class="primary--text">الصلاحيات: </span>
      <v-chip
        class="ml-2 my-2"
        v-for="permission in admin.permissions"
        :key="permission"
      >
        {{ permissionTranslationMapper[permission] }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { permissionTranslationMapper } from "@/core/statics/PermissionsTranslationMapper";

export default {
  props: {
    admin: Object,
  },
  data() {
    return {
      permissionTranslationMapper: permissionTranslationMapper,
    };
  },
};
</script>

<style scoped></style>