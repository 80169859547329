import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainerResumesService from "../services/TrainerResumesService";
import TrainerResumeKeyTransformations from "../models/TrainerResumeKeyTransformations";
import PaginatedTrainerResumeCollection from "../models/PaginatedTrainerResumeCollection";

export const useTrainerResumesStore = defineStore("trainerResumesStore", {
  state: () => ({
    paginatedTrainerResumes: new PaginatedTrainerResumeCollection(),
    trainerResumesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getFilterSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.trainerResumesTableOptions.sortBy.length != 0
          ? {
              sort_by:
              TrainerResumeKeyTransformations[
                  this.trainerResumesTableOptions.sortBy[0]
                ],
              sort_desc: this.trainerResumesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.trainerResumesTableOptions.page,
        ...sortObj,
        items_per_page: this.trainerResumesTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.trainerResumesTableOptions = options;
      this.fetchTrainerResumes();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedResumes();
      this.fetchTrainerResumes();
    },
    resetPaginatedResumes() {
      this.paginatedTrainerResumes = new PaginatedTrainerResumeCollection();
    },
    fetchTrainerResumes() {
      return BaseStore.promiseHandler(
        () =>
        TrainerResumesService.getTrainerResumes(
            this.getFilterSearchPageParameters
          ),
        (data) => {
          this.paginatedTrainerResumes = new PaginatedTrainerResumeCollection(
            data
          );
        }
      );
    },
  },
});
