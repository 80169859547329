import Resumable from "@/core/classes/resumable/Resumable";
import BaseService from "@/core/classes/base/BaseService";
import TrainingProgram from "../models/TrainingProgram";
import Client from "@/core/classes/client/Client";
import {
  getTrainingProgramAPI,
  getTrainingProgramsAPI,
  editTrainingProgramAPI,
  createTrainingProgramsAPI,
  deleteTrainingProgramAPI,
  uploadTrainingProgramAPI,
} from "./TrainingPrograms.api";

export default class TrainingProgramsService extends BaseService {
  static getTrainingPrograms(params) {
    return Client.get(getTrainingProgramsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getTrainingProgram(id) {
    return Client.get(getTrainingProgramAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static createTrainingProgram(data) {
    return Client.post(
      createTrainingProgramsAPI,
      TrainingProgram.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
  static editTrainingProgram(id, data) {
    return Client.put(
      editTrainingProgramAPI(id),
      TrainingProgram.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
  static deleteTrainingProgram(id) {
    return Client.delete(deleteTrainingProgramAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static uploadTrainingProgramVideo(id, video) {
    Resumable.opts.target = 
    process.env.VUE_APP_API_BASE_URL + uploadTrainingProgramAPI(id);
    Resumable.addFiles([video]);
  }
}
