<script>
import { mapState } from "pinia";
import { useAuthStore } from "@/modules/auth/store";

export default {
  computed: {
    ...mapState(useAuthStore, ["permissions"]),
  },
  methods: {
    hasPermission(permission) {
      return (
        this.permissions &&
        this.permissions.includes(
          permission.replace(/'/g, "").replace(/"/g, "")
        )
      );
    },
  },
};
</script>
