import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgramsService from "../services/TrainingProgramsService";
import TrainingProgramKeyTransformations from "../models/TrainingProgramKeyTransformations";
import PaginatedTrainingProgramCollection from "../models/PaginatedTrainingProgramCollection";

export const useTrainingProgramsStore = defineStore("trainingProgramsStore", {
  state: () => ({
    paginatedTrainingPrograms: new PaginatedTrainingProgramCollection(),
    allTrainingPrograms: [],
    trainingProgramsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.trainingProgramsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                TrainingProgramKeyTransformations[
                  this.trainingProgramsTableOptions.sortBy[0]
                ],
              sort_desc: this.trainingProgramsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.trainingProgramsTableOptions.page,
        ...sortObj,
        items_per_page: this.trainingProgramsTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.trainingProgramsTableOptions = options;
      this.fetchTrainingPrograms();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedTrainingPrograms();
      this.fetchTrainingPrograms();
    },
    resetPaginatedTrainingPrograms() {
      this.paginatedTrainingPrograms = new PaginatedTrainingProgramCollection();
    },
    fetchTrainingPrograms() {
      return BaseStore.promiseHandler(
        () =>
          TrainingProgramsService.getTrainingPrograms(
            this.getSearchPageParameters
          ),
        (data) => {
          this.paginatedTrainingPrograms =
            new PaginatedTrainingProgramCollection(data);
        }
      );
    },
    fetchAllTrainingPrograms() {
      return BaseStore.promiseHandler(
        () => TrainingProgramsService.getTrainingPrograms({ is_paginated: 0 }),
        (data) => {
          this.allTrainingPrograms = data?.data;
        }
      );
    },
  },
});
