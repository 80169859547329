<template>
  <div v-can:[createPermission]>
    <v-btn color="accent" @click="openCreateDialog()"><v-icon>mdi-plus</v-icon> إضافة مجال </v-btn>
    <CreateJobFieldDialog
      :showCondition="showCreateDialog"
      @closed="closeCreateDialog"
    />
  </div>
</template>

<script>
import CreateJobFieldDialog from "../dialogs/CreateJobFieldDialog.vue";
import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";

export default {
  data() {
    return {
      createPermission: WRITE_JOBS_PERMISSION,
      showCreateDialog: false,
    };
  },
  methods: {
    openCreateDialog() {
      this.showCreateDialog = true;
    },
    closeCreateDialog() {
      this.showCreateDialog = false;
    },
  },
  components: { CreateJobFieldDialog },
};
</script>
