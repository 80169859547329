<template>
  <v-container v-can:[showPermission]>
    <v-row>
      <v-col cols="10" md="12" align="center">
        <v-row>
          <h3 class="mb-5 secondary--text">
            لمحة عن مستخدمي المنصة
            <span class="mr-2 text-subtitle-2 primary--text">
              (قد لا تعكس الإحصاءات التالية بالضرورة نشاط المنصة في آخر 24 ساعة)
            </span>
          </h3>
        </v-row>
        <v-row justify="space-around">
          <NewUsersCountPerDurationCard />
          <UsersTotalCountCard />
          <CareerAptitudeTestResultsCountCard />
          <PlatformCreatedResumesTotalCountCard />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { READ_USERS_PERMISSION } from "@/core/statics/Permissions";
import { mapActions } from "pinia";
import { useStatisticsStore } from "../store/StatisticsStore";
import { StatisticsType } from "./statics/StatisticsTypes";

import NewUsersCountPerDurationCard from "./statistics_cards/users/NewUsersCountPerDurationCard.vue";
import UsersTotalCountCard from "./statistics_cards/users/UsersTotalCountCard.vue";
import CareerAptitudeTestResultsCountCard from "./statistics_cards/users/CareerAptitudeTestResultsCountCard.vue";
import PlatformCreatedResumesTotalCountCard from "./statistics_cards/users/PlatformCreatedResumesTotalCountCard.vue";

export default {
  components: {
    NewUsersCountPerDurationCard,
    UsersTotalCountCard,
    CareerAptitudeTestResultsCountCard,
    PlatformCreatedResumesTotalCountCard,
  },
  mounted() {
    this.fetchStatistics(StatisticsType.UsersStatistics)
      ?.then()
      ?.catch((err) => console.error(err));
  },
  data() {
    return {
      showPermission: READ_USERS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useStatisticsStore, ["fetchStatistics"]),
  },
};
</script>
